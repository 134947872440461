import {
  Alert,
  Box,
  Drawer,
  Snackbar,
} from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { CalendarHeaderContainer } from "../organism/calendar/calendarHeaderContainer";
import {
  ContainedButton,
  OverFlowBox,
  Typography14_400,
} from "../atom";
import {
  CustomTag,
  DepartmentDetail,
  GenericMasterData,
  LocationDepartmentDetail,
  LocationDetail,
  SearchShift,
  Shift,
  StaffDetail,
  UnitDetail,
} from "../../libs/model-lib";
import {
  customTagListState,
  errorAlertState,
  errorMessageState,
  loadingIconState,
  organizationDetailState,
  orgDepartmentListState,
  orgUnitDetailListState,
  shareShiftState,
  weekDayStartListState,
} from "../../libs/state-management-lib";
import {
  getOrganizationRoles,
  getShiftBreakTypeList,
  getAllLocationsByOrgId,
  getAllShifts,
  getStaffTypeList,
  getStaffByOrgIdLocId,
  getRecurrencePeriodList,
  getRoleByOrganizationId,
  getMappedAgency,
  getAllDepartmentsByOrgId,
  getSubDepartmentByOrgId,
  getCustomTagByOrgId,
  getWeekDayStartList,
} from "../../libs/services-lib";
import { format, previousMonday, previousSaturday, previousSunday } from "date-fns";
import {
  EmptyGuid,
  emptyLocationDepartmentDetail,
  EmptyLocationDetail,
  emptyShift,
  Encryption,
  getDateRange,
} from "../../libs/common-lib";
import {
  CalendarDateContainer,
  CalendarDepartmentContainer,
  CalendarShiftContainer,
} from "../organism";
import { locationDetailListState } from "../../libs/state-management-lib/locationState";
import { CalendarAddShitContainer } from "../organism/calendar/calendarAddShiftContainer";
import { CalendarStaffFilterModal } from "../organism/calendar/calendarStaffFilterModal";
import { CalendarAdvancedFilterModal } from "../organism/calendar/calendarAdvancedFiltermodal";
import dayjs from "dayjs";
import { CalendarEmptyAddShiftButtonBox } from "../atom/box/calendarBoxes/calendarEmptyAddShiftButtonBox";
import { useNavigate } from "react-router-dom";
import { CalendarShareShiftDrawer } from "../organism/calendar/calendarShareShiftcontainer";
import { SearchShiftContext } from "../../libs/context-lib/shiftDetailContext";

export const CalendarTemplate = () => {
  const [openAddShiftContainer, setOpenAddShiftContainer] = useState(false);
  const [staffFilter, setStaffFilter] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [departmentExpandIds, setDepartmentExpandIds] = useState<string[]>([]);
  const [shiftDetail, setShiftDetail] = useState<Shift>(emptyShift);
  const [shiftLocationId, setShiftLocationId] = useState<string>(EmptyGuid);
  const [shiftDate, setShiftDate] = useState<Date>(new Date());
  const [editedShiftStaffDetail, setEditedStaffDetail] =
    useState<StaffDetail | null>(null);
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const navigate = useNavigate();
  const [openShareShiftDrawer, setOpenShareShiftDrawer] = useState(false);
  const [locationList, setLocationList] = useState<LocationDetail[]>([]);
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [subDepartmentList, setSubDepartmentList] = useState<UnitDetail[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationDetail>(EmptyLocationDetail);
  const [selectedDepartment, setSelectedDepartment] = useState<DepartmentDetail[]>([]);
  const [customTagList, setCustomTagList] = useState<CustomTag[]>([]);
  const [weekDayStartList, setWeekDayStartList] = useState<GenericMasterData[]>([]);
  const [allShifts, setAllShifts] = useState<Shift[]>([]);
  const [staffDetailList, setStaffDetailList] = useState<StaffDetail[]>([]);
  const [addShiftSelection, setAddShiftSelection] = useState<number>(1);
  const [originalShiftDetail, setOriginalShiftDetail] = useState<string>("");

  const [searchShift, setSearchShift] = useState<SearchShift>({
    from_date: "",
    to_date: "",
    role_ids: [],
    department_ids: [],
    status: 0,
    location_id: EmptyGuid,
    orgId: organizationDetailState.getState().id,
    shift_type: 0,
    sub_department_ids: [],
  });

  useLayoutEffect(() => {
    const locationDetailListStateSub = locationDetailListState.subscribe(
      (state) => {
        setLocationList(state.locationDetailList);
      }
    );

    const departmentListStateSub = orgDepartmentListState.subscribe((state) => {
      setDepartmentList(state.departmentDetailList);
    });

    const subDepartmentListStateSub = orgUnitDetailListState.subscribe(
      (state) => {
        setSubDepartmentList(state.orgUnitDetailList);
      }
    );

    const errorAlertStateSub = errorAlertState.subscribe((state) => {
      setShowErrorAlert(state);
    });

    const errorMessageStateSub = errorMessageState.subscribe(async (state) => {
      setShowErrorMessage(state.errorMessage);
    });

    const customTagListStateSub = customTagListState.subscribe(
      async (state) => {
        if (!!state && !!state.customTagList && state.customTagList.length > 0) {
          setCustomTagList(state.customTagList);
        }
      }
    );

    const weekDayStartListStateSub = weekDayStartListState.subscribe(
      async (state) => {
        setWeekDayStartList(state.weekDayStartList);
      }
    );

    return () => {
      locationDetailListStateSub();
      departmentListStateSub();
      subDepartmentListStateSub();
      errorAlertStateSub();
      errorMessageStateSub();
      customTagListStateSub();
      weekDayStartListStateSub();
    };
  }, []);

  useEffect(() => {
    (async function () {
      loadingIconState.setState(true);

      await Promise.all([
        getStaffTypeList(),
        getWeekDayStartList(),
        getOrganizationRoles(organizationDetailState.getState().id),
        getShiftBreakTypeList(),
        getAllLocationsByOrgId(organizationDetailState.getState().id),
        getAllDepartmentsByOrgId(organizationDetailState.getState().id),
        getSubDepartmentByOrgId(organizationDetailState.getState().id),
        getStaffTypeList(),
        getRecurrencePeriodList(),
        getRoleByOrganizationId(organizationDetailState.getState().id),
        getMappedAgency(organizationDetailState.getState().id),
        getCustomTagByOrgId(organizationDetailState.getState().id)
      ]);
      loadingIconState.setState(false);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (
        !!searchShift.from_date &&
        !!searchShift.to_date &&
        !!searchShift.department_ids &&
        searchShift.department_ids.length > 0
      ) {
        loadingIconState.setState(true);

        let aShifts = await getAllShifts(searchShift);
        setAllShifts(aShifts);

        if (!!selectedLocation && selectedLocation.id != EmptyGuid) {
          let sList = await getStaffByOrgIdLocId(
            organizationDetailState.getState().id,
            selectedLocation.id
          );

          setStaffDetailList(sList);
        }
        loadingIconState.setState(false);
      }
    })();
  }, [searchShift]);

  const handleShowStaffFilterClick = () => {
    setStaffFilter(true);
  };

  const handleCancelStaffFilterClick = () => {
    setStaffFilter(false);
  };

  const handleShowAdvancedFilterClick = () => {
    setAdvancedFilter(true);
  };

  const handleCancelAdvancedFilterClick = () => {
    setAdvancedFilter(false);
  };

  const handleShowShareShiftDrawer = async (
    selectedLocationId: string,
    selectedDepartment: any
  ) => {
    setOpenShareShiftDrawer(true);
    loadingIconState.setState(true);
    await Promise.all([
      getRoleByOrganizationId(organizationDetailState.getState().id),
      getMappedAgency(organizationDetailState.getState().id),
    ]);
    loadingIconState.setState(false);
    shareShiftState.setState({
      location_id: selectedLocationId,
      department_ids: selectedDepartment,
    });
  };

  const handleCollapseClick = (expandItem: boolean, departId: string) => {
    let departIds = [...departmentExpandIds];
    if (!expandItem) {
      departIds.push(departId);
    } else {
      departIds = departIds.filter((cc) => cc !== departId);
    }

    setDepartmentExpandIds(departIds);
  };

  const handleAddShiftOpen = (
    shiftOpen: boolean,
    locationDepartmentDetail: LocationDepartmentDetail,
    shiftDate: Date,
    staffDetail?: StaffDetail
  ) => {
    let shiftDetail = { ...emptyShift };
    shiftDetail.department_id = locationDepartmentDetail.department_id;
    shiftDetail.staff_type_ids = [];

    if (!!staffDetail) {
      shiftDetail.staff_type_ids = [staffDetail.staff_type];
      shiftDetail.role_ids = staffDetail.role_ids;
    }

    if (
      !!searchShift.sub_department_ids &&
      searchShift.sub_department_ids.length > 0
    ) {
      shiftDetail.sub_department_id =
      searchShift.sub_department_ids[0];
    }

    setShiftDate(shiftDate);
    setShiftLocationId(locationDepartmentDetail.location_id);
    setShiftDetail(shiftDetail);
    setOriginalShiftDetail(JSON.stringify(shiftDetail));
    setOpenAddShiftContainer(shiftOpen);
    setEditedStaffDetail(!!staffDetail ? staffDetail : null);
  };

  const handleEditShiftOpen = (
    shiftOpen: boolean,
    shiftDetail: Shift,
    locationDepartmentDetail: LocationDepartmentDetail
  ) => {
    let editedShiftDetail = { ...shiftDetail };
    editedShiftDetail.staff_type_ids = shiftDetail.staff_type_ids;
    let shiftDate = new Date(editedShiftDetail.start_date);
    shiftDate.setHours(0, 0, 0, 0);
    editedShiftDetail.number_of_shifts = 1;

    setShiftDate(shiftDate);
    setShiftLocationId(locationDepartmentDetail.location_id);
    setShiftDetail(editedShiftDetail);
    setOpenAddShiftContainer(shiftOpen);
    setEditedStaffDetail(
      !!editedShiftDetail.assigned_staff_detail
        ? editedShiftDetail.assigned_staff_detail
        : null
    );

    // let singleShiftDate = new Date(shiftDate);
    // singleShiftDate.setHours(0, 0, 0, 0);
    // let singleShiftDateJs = dayjs(singleShiftDate);

    // let shiftStartDate = !!editedShiftDetail.start_date
    //   ? singleShiftDateJs
    //       .add(dayjs(editedShiftDetail.start_date).hour(), "hour")
    //       .add(dayjs(editedShiftDetail.start_date).minute(), "minute")
    //       .toDate()
    //   : singleShiftDateJs.add(14, "hour").add(0, "minute").toDate();

    // let shiftEndDate = !!editedShiftDetail.end_date
    //   ? singleShiftDateJs
    //       .add(dayjs(editedShiftDetail.end_date).hour(), "hour")
    //       .add(dayjs(editedShiftDetail.end_date).minute(), "minute")
    //       .toDate()
    //   : singleShiftDateJs.add(22, "hour").add(0, "minute").toDate();
    if (
      dayjs(editedShiftDetail.start_date).hour() >
      dayjs(editedShiftDetail.end_date).hour()
    ) {
      let singleShiftDate = new Date(shiftDate);
      singleShiftDate.setHours(0, 0, 0, 0);
      let singleShiftDateJs = dayjs(singleShiftDate);

      let shiftStartDate = singleShiftDateJs
        .add(dayjs(editedShiftDetail.start_date).hour(), "hour")
        .add(dayjs(editedShiftDetail.start_date).minute(), "minute")
        .toDate();

      let shiftEndDate = singleShiftDateJs
        .add(1, "day")
        .add(dayjs(editedShiftDetail.end_date).hour(), "hour")
        .add(dayjs(editedShiftDetail.end_date).minute(), "minute")
        .toDate();

      editedShiftDetail.start_date = shiftStartDate;
      editedShiftDetail.end_date = shiftEndDate;
      editedShiftDetail.start_date_string = format(
        shiftStartDate,
        "yyyy-MM-dd HH:mm:SS"
      );
      editedShiftDetail.end_date_string = format(
        shiftEndDate,
        "yyyy-MM-dd HH:mm:SS"
      );
    } else {
      let singleShiftDate = new Date(shiftDate);
      singleShiftDate.setHours(0, 0, 0, 0);
      let singleShiftDateJs = dayjs(singleShiftDate);

      let shiftStartDate = singleShiftDateJs
        .add(dayjs(editedShiftDetail.start_date).hour(), "hour")
        .add(dayjs(editedShiftDetail.start_date).minute(), "minute")
        .toDate();

      let shiftEndDate = singleShiftDateJs
        .add(dayjs(editedShiftDetail.end_date).hour(), "hour")
        .add(dayjs(editedShiftDetail.end_date).minute(), "minute")
        .toDate();

      editedShiftDetail.start_date = shiftStartDate;
      editedShiftDetail.end_date = shiftEndDate;
      editedShiftDetail.start_date_string = format(
        shiftStartDate,
        "yyyy-MM-dd HH:mm:SS"
      );
      editedShiftDetail.end_date_string = format(
        shiftEndDate,
        "yyyy-MM-dd HH:mm:SS"
      );
    }

    setOriginalShiftDetail(JSON.stringify(editedShiftDetail));
  };

  const handleAddDepartmentClick = () => {
    navigate(`/settings/department/${Encryption(EmptyGuid)}`);
  };

  const handleAddLocationClick = () => {
    navigate(`/settings/location/${Encryption(EmptyGuid)}`);
  };

  const handleAddStaffClick = () => {
    navigate(`/settings/talent/${Encryption(EmptyGuid)}`);
  };

  const handleAddCommonShiftClick = () => {
    handleAddShiftOpen(
      true,
      {
        department_id:
          !!selectedDepartment &&
          selectedDepartment.length > 0 &&
          selectedDepartment.length === 1
            ? selectedDepartment[0].id
            : EmptyGuid,
        location_id: selectedLocation.id,
        department_name: !!selectedDepartment &&
        selectedDepartment.length > 0 &&
        selectedDepartment.length === 1
          ? selectedDepartment[0].name
          : EmptyGuid,
        location_name: selectedLocation.name,
      },
      new Date(new Date().setHours(0, 0, 0, 0)),
      undefined
    );
  };

  const options = [
    "Monthly planning",
    "Start from assignee",
    "Start from schedule",
  ];

  const handleLocationChange = async(locationDetail: LocationDetail) => {
    setSelectedLocation(locationDetail);
    let weekDayList: GenericMasterData[] = [];

    if (!!weekDayStartList && weekDayStartList.length > 0) {
      weekDayList = weekDayStartList;
    } else {
      weekDayList = await getWeekDayStartList();
      setWeekDayStartList(weekDayList);
    }

    let previsousDate = new Date();

    if(format(new Date(), "EEEE").toLocaleLowerCase() != "sunday") {
      previsousDate = previousSunday(new Date());
    }

    if (
      !!weekDayList &&
      weekDayList.length > 0 &&
      !!locationDetail.week_start_day &&
      locationDetail.week_start_day > 0
    ) {
      let startDayOfWeek = weekDayList.filter(
        (cc) => cc.id == locationDetail.week_start_day
      );

      if (!!startDayOfWeek && startDayOfWeek.length > 0) {

        if (startDayOfWeek[0].name.toLocaleLowerCase() === "saturday") {
          if (format(new Date(), "EEEE").toLocaleLowerCase() !== "saturday") {
            previsousDate = previousSaturday(new Date());
          }
        }

        if (startDayOfWeek[0].name.toLocaleLowerCase() === "sunday") {
          if (format(new Date(), "EEEE").toLocaleLowerCase() !== "sunday") {
            previsousDate = previousSunday(new Date());
          }
        }

        if (startDayOfWeek[0].name.toLocaleLowerCase() === "monday") {
          if (format(new Date(), "EEEE").toLocaleLowerCase() !== "monday") {
            previsousDate = previousMonday(new Date());
          }
        }
      }
    }

    let periodEndDate = new Date(previsousDate);

    let dateRangeList = getDateRange(
      previsousDate,
      new Date(periodEndDate.setDate(periodEndDate.getDate() + 6)),
      1
    );

    setDateRange(dateRangeList);
  }

  const handleDepartmentChange = (departmentDetailList: DepartmentDetail[]) => {
    setSelectedDepartment(departmentDetailList);
  }

  const handleNewShiftButtonClick = (index: number) => {
    setAddShiftSelection(index);
    handleAddShiftOpen(
      true,
      {
        department_id:
          !!selectedDepartment &&
          selectedDepartment.length > 0 &&
          selectedDepartment.length == 1
            ? selectedDepartment[0].id
            : EmptyGuid,
        location_id: selectedLocation.id,
        department_name: !!selectedDepartment &&
        selectedDepartment.length > 0 &&
        selectedDepartment.length == 1
          ? selectedDepartment[0].name
          : EmptyGuid,
        location_name: selectedLocation.name,
      },
      new Date(new Date().setHours(0, 0, 0, 0)),
      undefined
    );
  };

  return (
    <>
      {!!!locationList ||
        (locationList.length <= 0 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                padding: "40px 8px 8px 8px",
                width: "100%",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Typography14_400
                text="There is no location added yet. Add them first, so you can create the shift."
                color="#737F8C"
              />
              <ContainedButton
                sx={{
                  height: "32px",
                  padding: "4px 12px 4px 12px",
                  width: "200px",
                }}
                text={"Add location"}
                handleClick={handleAddLocationClick}
              />
            </Box>
          </Box>
        ))}

      {!!locationList && locationList.length > 0 && (
        <>
          {staffFilter && (
            <CalendarStaffFilterModal
              handleCancelStaffFilterClick={handleCancelStaffFilterClick}
            />
          )}

          {advancedFilter && (
            <CalendarAdvancedFilterModal
              handleCancelAdvancedFilterClick={handleCancelAdvancedFilterClick}
            />
          )}
          <SearchShiftContext.Provider value={{ searchShift, setSearchShift, allShifts, setAllShifts, dateRange, setDateRange }}>
            <Drawer
              anchor="right"
              open={openAddShiftContainer}
              onClose={() => {
                setAddShiftSelection(1);
              }}
              PaperProps={{
                sx: { width: "700px" },
              }}
            >
              <CalendarAddShitContainer
                handleCancelClick={() => {
                  setOpenAddShiftContainer(false);
                  setAddShiftSelection(1);
                }}
                shiftDetail={shiftDetail}
                orginalShiftDetail={originalShiftDetail}
                locationId={shiftLocationId}
                shiftDate={dayjs(shiftDate)}
                staffDetail={editedShiftStaffDetail}
                customTagList={customTagList}
                addShiftSelection={addShiftSelection}
              />
            </Drawer>

            <Drawer
              anchor="right"
              open={openShareShiftDrawer}
              PaperProps={{
                sx: { width: "700px" },
              }}
            >
              <CalendarShareShiftDrawer
                handleCloseClick={() => {
                  setOpenShareShiftDrawer(false);
                }}
                locationId={selectedLocation.id}
                departmentId={searchShift.department_ids}
              />
            </Drawer>

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                background: "#F5FAFE"
              }}
            >
              {/* Header container */}
              <CalendarHeaderContainer
                handleShowAdvancedFilterModal={handleShowAdvancedFilterClick}
                handleShowShareShiftDrawer={handleShowShareShiftDrawer}
                options={options}
                locationList={locationList}
                departmentList={departmentList}
                subDepartmentList={subDepartmentList}
                handleLocationChange={handleLocationChange}
                handleDepartmentChange={handleDepartmentChange}
                handleShowCreateShiftDrawer={handleNewShiftButtonClick}
        />
              {/* Body Container */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  height: "calc(88% - 16px)",
                }}
              >
                {/* right pane */}
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    background: "#F5FAFE",
                    flexDirection: "column",
                  }}
                >
                  <CalendarDateContainer
                    handleShowStaffFilterClick={handleShowStaffFilterClick}
                  />
                  <OverFlowBox
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {!!departmentExpandIds &&
                      departmentList.length > 0 &&
                      !!selectedDepartment &&
                      selectedDepartment.length > 0 &&
                      selectedDepartment.map(
                        (departmentDetail: DepartmentDetail) => (
                          <Box key={departmentDetail.id}>
                            <CalendarDepartmentContainer
                              locationDepartDetail={{
                                department_id: departmentDetail.id,
                                location_id: selectedLocation.id,
                                department_name: departmentDetail.name,
                                location_name: selectedLocation.name,
                              }}
                              handleCollapseClick={(expandItem: boolean) => {
                                handleCollapseClick(
                                  expandItem,
                                  departmentDetail.id
                                );
                              }}
                              handleAddShiftOpen={handleAddShiftOpen}
                            />
                            <CalendarShiftContainer
                              locationDepartmentDetail={{
                                department_id: departmentDetail.id,
                                location_id: selectedLocation.id,
                                department_name: departmentDetail.name,
                                location_name: selectedLocation.name,
                              }}
                              handleAddShiftDepartmentClick={() => {}}
                              expanded={
                                departmentExpandIds.filter(
                                  (cc) => cc == departmentDetail.id
                                ).length <= 0
                              }
                              handleAddShiftOpen={handleAddShiftOpen}
                              handleEditShiftOpen={handleEditShiftOpen}
                              allShifts={allShifts}
                              staffDetailList={staffDetailList}
                              dateRange={dateRange}
                            />
                          </Box>
                        )
                      )}

                    {selectedDepartment.length <= 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Box
                          sx={{
                            width: "16%",
                            display: "flex",
                            flexDirection: "column",
                            border: "1px solid #E7F2FE",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "center",
                              padding: "40px 8px 8px 8px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                padding: "0px 16px 0px 16px",
                              }}
                            >
                              <Typography14_400
                                text="There is no department or talent added yet. Add them first, so you can assign the shift."
                                color="#737F8C"
                              />
                              <ContainedButton
                                sx={{
                                  height: "32px",
                                  padding: "4px 12px 4px 12px",
                                }}
                                text={"Add department"}
                                handleClick={handleAddDepartmentClick}
                              />
                              <ContainedButton
                                sx={{
                                  height: "32px",
                                  padding: "4px 12px 4px 12px",
                                }}
                                text={"Add talent"}
                                handleClick={handleAddStaffClick}
                              />
                            </Box>
                          </Box>
                        </Box>
                        {!!dateRange &&
                          dateRange.length > 0 &&
                          dateRange.map((cc: Date, index: number) => (
                            <Box
                              sx={{
                                width: "calc(84% / 7)",
                                fontSize: "12px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                color: "#CFE5FC",
                                borderRight: "1px solid #E7F2FE",
                              }}
                              key={index}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  height: "100%",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  padding: "8px 0px 8px 0px",
                                  border: "1px solid #E7F2FE",
                                  minHeight: "96px",
                                }}
                              >
                                <CalendarEmptyAddShiftButtonBox
                                  locationDepartmentDetail={
                                    emptyLocationDepartmentDetail
                                  }
                                  handleAddShiftOpen={handleAddShiftOpen}
                                  shiftDate={cc}
                                />
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    )}
                  </OverFlowBox>
                </Box>
              </Box>
            </Box>
          </SearchShiftContext.Provider>

          {/* <Avatar
            sx={{
              width: "2.3%",
              height: "4.5%",
              position: "absolute",
              top: "92%",
              left: "96%",
              cursor: "pointer",
              background: "#0D80F2",
              borderRadius: "8px",
              zIndex: "1000",
            }}
            onClick={handleAddCommonShiftClick}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.99995 0.200195C9.44178 0.200195 9.79995 0.558368 9.79995 1.0002V8.2002H17C17.4418 8.2002 17.7999 8.55837 17.7999 9.0002C17.7999 9.44202 17.4418 9.8002 17 9.8002H9.79995V17.0002C9.79995 17.442 9.44178 17.8002 8.99995 17.8002C8.55812 17.8002 8.19995 17.442 8.19995 17.0002V9.8002H0.999951C0.558123 9.8002 0.199951 9.44202 0.199951 9.0002C0.199951 8.55837 0.558123 8.2002 0.999951 8.2002H8.19995V1.0002C8.19995 0.558368 8.55812 0.200195 8.99995 0.200195Z"
                fill="white"
              />
            </svg>
          </Avatar> */}

          <Snackbar
            open={showErrorAlert}
            autoHideDuration={6000}
            onClose={() => {
              errorAlertState.setState(false);
            }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            sx={{ maxWidth: "600px", width: "600px" }}
          >
            <Alert
              onClose={() => {
                errorAlertState.setState(false);
              }}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {showErrorMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};
