import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { CalendarDateBox } from "../../atom/box/calendarBoxes/calendarDateBox";
import { calendarStaffTabValueState } from "../../../libs/state-management-lib/staffState";
import { useSearchShiftContext } from "../../../libs/context-lib/shiftDetailContext";

interface ICalendarDateContainer {
  handleShowStaffFilterClick: () => void;
}

export const CalendarDateContainer = (props: ICalendarDateContainer) => {
  const [staffTabValue, setStaffTabValue] = useState(0);
  const { dateRange, setDateRange } = useSearchShiftContext();

  const handleStaffTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setStaffTabValue(newValue);
    calendarStaffTabValueState.setState(newValue);
  };

  return (
    <Box
      sx={{
        width: "calc(100% - 5px)",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          width: "16%",
          display: "flex",
          flexDirection: "column",
          borderRight: "1px solid #C4D9EF",
        }}
      >
        <Box
          sx={{
            width: "calc(100%-16px)",
            display: "flex",
            flexDirection: "column",
            padding: "16px 8px 0px 8px",
            background: "#FFFFFF",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                width: "50%",
                alignContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "600",
                  color: "#031A30",
                }}
              >
                Talent
              </Typography>
            </Box>
            {/* <Box
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                paddingRight: "2%",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                  cursor: "pointer",
                }}
                onClick={props.handleShowStaffFilterClick}
              >
                <FilterIcon sx={{ color: "#0D80F2" }} />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  cursor: "pointer",
                  paddingTop: "3%",
                }}
              >
                <SearchIcon sx={{ color: "#0D80F2" }} />
              </Box>
            </Box> */}
          </Box>
        </Box>

        <Box
          sx={{
            width: "99%",
            height: "92%",
            display: "flex",
            flexDirection: "row",
            background: "#FFFFFF",
          }}
        ></Box>
        <Box>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: "#0D80F2",
              },
            }}
            indicatorColor="primary"
            sx={{
              maxWidth: "100%",
              minHeight: "32px !important",
              maxHeight: "40px !important",
              background: "#FFFFFF",
            }}
            value={staffTabValue}
            onChange={handleStaffTabChange}
            variant="fullWidth"
          >
            <Tab
              sx={{
                minWidth: "33.33%",
                width: "33.33%",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "600",
                lineHeight: "22.4px",
                color: "#0D80F2",
                "&.Mui-selected": {
                  color: "#031A30",
                },
              }}
              label="All"
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
            />
            <Tab
              sx={{
                minWidth: "33.33%",
                width: "33.33%",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "600",
                lineHeight: "22.4px",
                color: "#0D80F2",
                "&.Mui-selected": {
                  color: "#031A30",
                },
              }}
              label="Internal"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
            <Tab
              sx={{
                minWidth: "33.33%",
                width: "33.33%",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "600",
                lineHeight: "22.4px",
                color: "#0D80F2",
                "&.Mui-selected": {
                  color: "#031A30",
                },
              }}
              label="External"
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
            />
          </Tabs>
        </Box>
      </Box>
      <Box
        sx={{
          width: "84%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!!dateRange &&
          dateRange.length > 0 &&
          dateRange.map((cc: Date, index: number) => {
            if (index == 0) {
              return (
                <CalendarDateBox
                  date={cc}
                  showLeftIcon={true}
                  showRightIcon={false}
                  key={cc.getDate() + "-" + cc.getMonth()}
                />
              );
            } else if (index == dateRange.length - 1) {
              return (
                <CalendarDateBox
                  date={cc}
                  showLeftIcon={false}
                  showRightIcon={true}
                  key={cc.getDate() + "-" + cc.getMonth()}
                />
              );
            } else {
              return (
                <CalendarDateBox
                  date={cc}
                  showLeftIcon={false}
                  showRightIcon={false}
                  key={cc.getDate() + "-" + cc.getMonth()}
                />
              );
            }
          })}
      </Box>
    </Box>
  );
};
