import {
  SxProps,
  Typography,
  Theme,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";

interface ITypography {
  text: string;
  color?: string;
  sx?: SxProps<Theme>;
  required?: boolean;
  infoIcon?: boolean;
  toolTip?: string;
}

export const Typography14_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "14px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography10_400 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "10px",
        fontWeight: "400",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography10_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "10px",
        fontWeight: "400",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography18_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "18px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text} {!!props.required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "200px",
    backgroundColor: "#17191C",
    color: "#F9FAFA",
  },
});

export const Typography12_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "12px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text} {!!props.required && <span style={{ color: "red" }}>*</span>}
      {!!props.infoIcon && (
        <span style={{ paddingLeft: "1%" }}>
          <CustomTooltip title={props.toolTip} placement="top">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.00039 1.59844C4.01776 1.59844 1.60039 4.01581 1.60039 6.99844C1.60039 9.98107 4.01776 12.3984 7.00039 12.3984C9.98302 12.3984 12.4004 9.98107 12.4004 6.99844C12.4004 4.01581 9.98302 1.59844 7.00039 1.59844ZM0.400391 6.99844C0.400391 3.35307 3.35502 0.398438 7.00039 0.398438C10.6458 0.398438 13.6004 3.35307 13.6004 6.99844C13.6004 10.6438 10.6458 13.5984 7.00039 13.5984C3.35502 13.5984 0.400391 10.6438 0.400391 6.99844ZM6.06706 4.83395C6.06449 4.31535 6.48526 3.89844 6.99906 3.89844C7.5142 3.89844 7.93373 4.31484 7.93373 4.83177C7.93373 5.34714 7.51577 5.7651 7.0004 5.7651C6.48575 5.7651 6.06824 5.34832 6.06706 4.83395ZM6.06706 4.83395C6.06706 4.83322 6.06706 4.8325 6.06706 4.83177H6.66705L6.06708 4.83612C6.06707 4.83539 6.06707 4.83467 6.06706 4.83395ZM7.26704 4.83177C7.26705 4.83106 7.26705 4.83035 7.26705 4.82965C7.26705 4.82904 7.26704 4.82844 7.26703 4.82784M7.00039 6.39844C7.33176 6.39844 7.60039 6.66707 7.60039 6.99844V10.3318C7.60039 10.6631 7.33176 10.9318 7.00039 10.9318C6.66902 10.9318 6.40039 10.6631 6.40039 10.3318V6.99844C6.40039 6.66707 6.66902 6.39844 7.00039 6.39844Z"
                fill="#737F8C"
              />
            </svg>
          </CustomTooltip>
        </span>
      )}
    </Typography>
  );
};

export const Typography16_400 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "16px",
        fontWeight: "400",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography14_400 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "14px",
        fontWeight: "400",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography12_400 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "12px",
        fontWeight: "400",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography32_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "32px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text} {!!props.required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

export const Typography16_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "16px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text} {!!props.required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};

export const Typography24_500 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "24px",
        fontWeight: "500",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography24_600 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "24px",
        fontWeight: "600",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography15_500 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "15px",
        fontWeight: "500",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};

export const Typography14_500 = (props: ITypography) => {
  return (
    <Typography
      sx={{
        ...props.sx,
        fontSize: "14px",
        fontWeight: "500",
        color: !!props.color
          ? props.color
          : (theme) => theme.palette.primary.contrastText,
        textTransform: "none",
      }}
    >
      {props.text}
    </Typography>
  );
};
