import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  Stack,
  Chip,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  ListSubheader,
} from "@mui/material";
import { EmptyGuid, getErrorDetail } from "../../libs/common-lib";
import {
  GenericMasterData,
  OrgRole,
  Shift,
  StaffDetail,
} from "../../libs/model-lib";
import {
  Typography16_400,
  LableTextFieldBox,
  MultiSelectDropdown,
  Typography14_400,
  PlatformTextField,
} from "../atom";
import { CalendarRotationTalentBox } from "../atom/box/calendarBoxes/calendarRotationTalentBox";
import { TextFieldLableTypography } from "../atom/typography/textFieldLableTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseDropDownIcon from "@mui/icons-material/Close";
import SearchIconText from "@mui/icons-material/Search";
import { FormCheckbox } from "../atom/checkbox/formCheckbox";

interface ICalendarTalentForm {
  staffTypeList: GenericMasterData[];
  assignedStaffDetail: StaffDetail[];
  shiftDetail: Shift;
  orgRoleList: OrgRole[];
  selectedStaffIds: string[];
  filteredStaffList: StaffDetail[];
  nonFilteredStaffList: StaffDetail[];
  searchAssignee: string;
  agencyName: string;
  handleStaffChange: (e: any) => void;
  handleRoleChange: (e: any) => void;
  handleSelectStaffClick: (e: any) => void;
  handleRoleRemove: (e: any) => void;
  getSelectedStaffLableValue: (staffDetailId: string) => string;
  getSelectedStaffRoleDetail: (staffDetailId: string) => string;
  handleStaffRemove: (e: any) => void;
  handleSearchAssignee: (event: any) => void;
  errorMessage: any[];
}

const CalendarTalentForm = (props: ICalendarTalentForm) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 32px)",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        border: "1px solid #F1F2F4",
        padding: "16px",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            color: "#17191C",
            lineHeight: "24.3px",
          }}
        >
          Talent
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            paddingLeft: "2%",
          }}
        >
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {props.staffTypeList.map((cc) => (
              <FormControlLabel
                sx={{
                  alignContent: "center",
                }}
                value={cc.id}
                key={cc.id}
                disabled={
                  !!props.assignedStaffDetail &&
                  props.assignedStaffDetail.length > 0
                    ? true
                    : false
                }
                checked={
                  !!props.shiftDetail.staff_type_ids &&
                  props.shiftDetail.staff_type_ids.filter((dd) => dd == cc.id)
                    .length > 0
                    ? true
                    : false
                }
                control={
                  <Radio
                    size="medium"
                    sx={{
                      color: "#ABB2BA",
                      "&.Mui-checked": {
                        color: "#0D80F2",
                      },
                      "&:hover": {
                        color: "#0A66C2",
                      },
                    }}
                  />
                }
                label={<Typography16_400 text={cc.name} color="#000000DE" />}
                onChange={props.handleSelectStaffClick}
              />
            ))}
          </RadioGroup>          
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          color: "#737F8C",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <LableTextFieldBox>
          <TextFieldLableTypography text="Qualification" required={true} />
        </LableTextFieldBox>
        <MultiSelectDropdown
          sx={{
            "&.Mui-disabled": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #ABB2BA !important",
              },
            },
          }}
          placeholder="Select one or a few qualifications"
          handleChange={props.handleRoleChange}
          value={props.shiftDetail.role_ids}
          label={""}
          listItems={props.orgRoleList}
          handleRemove={props.handleRoleRemove}
          labelValue="abbreviation"
          disabled={
            !!props.assignedStaffDetail && props.assignedStaffDetail.length > 0
              ? true
              : false
          }
          errorMessage={getErrorDetail("role_ids", props.errorMessage)}
        >
          <MenuItem value={EmptyGuid} disabled>
            Select one or a few roles
          </MenuItem>
          {props.orgRoleList.map((cc: OrgRole) => (
            <MenuItem value={cc.id} key={cc.id}>
              {cc.abbreviation}
            </MenuItem>
          ))}
        </MultiSelectDropdown>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          paddingLeft: "2%",
        }}
      >
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            //   value={props.assignType}
            //   onChange={(e: any) => {
            //     props.setAssignType(e.target.value);
            //   }}
          >
            <FormControlLabel
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#000000DE",
              }}
              value={0}
              control={
                <Radio
                  size="medium"
                  checked
                  sx={{
                    color: "#ABB2BA",
                    "&.Mui-checked": {
                      color: "#0D80F2",
                    },
                    "&:hover": {
                      color: "#0A66C2",
                    },
                  }}
                />
              }
              label="Assign to individual(s)"
            />
            <FormControlLabel
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#000000DE",
              }}
              disabled
              value={1}
              control={
                <Radio
                  size="medium"
                  sx={{
                    color: "#ABB2BA",
                    "&.Mui-checked": {
                      color: "#0D80F2",
                    },
                    "&:hover": {
                      color: "#0A66C2",
                    },
                  }}
                />
              }
              label="Assign to a team"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          width: "100%",
          color: "#737F8C",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LableTextFieldBox>
          <TextFieldLableTypography text="Assignee(s)" required={false} />
        </LableTextFieldBox>
        <Select
          placeholder="Select the assignee(s)"
          size="small"
          displayEmpty
          IconComponent={ExpandMoreIcon}
          sx={{
            width: "100%",
            background: "#F5FAFE",
            "& .MuiSvgIcon-root": { color: "#0D80F2" },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#CFE5FC",
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#0D80F2",
              borderRadius: "8px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #0D80F2 !important",
              borderRadius: "8px",
            },
            "& .MuiInputLabel-root": { display: "none" },
            "& .Mui-disabled.MuiSvgIcon-root": { color: "#B6B9BC !important" },
            "&.Mui-disabled": {
              background: "#F1F2F4 !important",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #ABB2BA !important",
              },
            },
            Input: {
              "&::placeholder": {
                textOverflow: "ellipsis !important",
                color: "red",
                opacity: "5",
              },
            },
          }}
          multiple
          value={props.selectedStaffIds}
          onChange={props.handleStaffChange}
          input={<OutlinedInput label="" />}
          renderValue={(selected) => {
            if (!!!selected || selected.length <= 0) {
              return (
                <Typography16_400
                  text="Select the assignee(s)"
                  color="#6EB2F7"
                />
              );
            }

            if (!!selected && selected.length <= 2) {
              return (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected?.map((value: any) => (
                    <Chip
                      disabled={
                        !!!props.shiftDetail.staff_type_ids ||
                        props.shiftDetail.staff_type_ids.length <= 0 ||
                        !!!props.shiftDetail.role_ids ||
                        props.shiftDetail.role_ids.length <= 0
                      }
                      sx={{
                        background: "#E7F2FE",
                        border: "1px solid #CFE6FC",
                        borderRadius: "4px",
                        height: "32px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                      }}
                      key={
                        !!props.nonFilteredStaffList &&
                        props.nonFilteredStaffList.length > 0
                          ? props.nonFilteredStaffList.filter(
                              (cc) => cc.id == value
                            )[0].id
                          : ""
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          <Typography14_400
                            text={props.getSelectedStaffLableValue(value)}
                            color="#031A30"
                          />
                          <Typography14_400
                            text={props.getSelectedStaffRoleDetail(value)}
                            color="#737F8C"
                          />
                        </Box>
                      }
                      onDelete={() => {
                        props.handleStaffRemove(value);
                      }}
                      deleteIcon={
                        <CloseDropDownIcon
                          sx={{ color: "#0D80F2 !important" }}
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Stack>
              );
            }

            if (!!selected && selected.length > 2) {
              return (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.slice(0, 2).map((value: any) => (
                    <Chip
                      disabled={
                        !!!props.shiftDetail.staff_type_ids ||
                        props.shiftDetail.staff_type_ids.length <= 0 ||
                        !!!props.shiftDetail.role_ids ||
                        props.shiftDetail.role_ids.length <= 0
                      }
                      sx={{
                        background: "#E7F2FE",
                        border: "1px solid #CFE6FC",
                        borderRadius: "4px",
                        height: "32px",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "22.4px",
                      }}
                      key={
                        !!props.nonFilteredStaffList &&
                        props.nonFilteredStaffList.length > 0
                          ? props.nonFilteredStaffList.filter(
                              (cc) => cc.id == value
                            )[0].id
                          : ""
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          <Typography14_400
                            text={props.getSelectedStaffLableValue(value)}
                            color="#031A30"
                          />
                          <Typography14_400
                            text={props.getSelectedStaffRoleDetail(value)}
                            color="#737F8C"
                          />
                        </Box>
                      }
                      onDelete={() => {
                        props.handleStaffRemove(value);
                      }}
                      deleteIcon={
                        <CloseDropDownIcon
                          sx={{ color: "#0D80F2 !important" }}
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}

                  <Chip
                    disabled={
                      !!!props.shiftDetail.staff_type_ids ||
                      props.shiftDetail.staff_type_ids.length <= 0 ||
                      !!!props.shiftDetail.role_ids ||
                      props.shiftDetail.role_ids.length <= 0
                    }
                    sx={{
                      background: "#E7F2FE",
                      border: "1px solid #CFE6FC",
                      borderRadius: "4px",
                      height: "32px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22.4px",
                    }}
                    label={`+${selected.length - 2}`}
                  />
                </Stack>
              );
            }
          }}
          disabled={
            !!!props.shiftDetail.staff_type_ids ||
            props.shiftDetail.staff_type_ids.length <= 0 ||
            !!!props.shiftDetail.role_ids ||
            props.shiftDetail.role_ids.length <= 0
          }
          MenuProps={{
            autoFocus: false,
            PaperProps: {
              sx: {
                maxHeight: "250px !important",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: "32px",
                  backgroundColor: "#E3E5E8",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#E3E5E8",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#0000000",
                },
              },
              style: {
                maxHeight: 250,
              },
            },
          }}
        >
          <ListSubheader sx={{ padding: "5px 9px 5px 15px !important" }}>
            <TextField
              autoFocus={true}
              size="small"
              sx={{
                width: "100%",
                padding: "0px !important",
                borderRadius: 8,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #CFE5FC",
                  },
                  "&:hover fieldset": {
                    "& .Mui-disabled": {
                      borderColor: "#F1F2F4 !important",
                    },
                    borderColor: "#CFE5FC",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#CFE5FC",
                  },
                },
                "& .Mui-disabled": {
                  background: "#F1F2F4 !important",
                  "&:hover fieldset": {
                    border: "1px solid #ABB2BA !important",
                  },
                },
                "& .Mui-disabled .MuiSvgIcon-root": {
                  color: "#ABB2BA !important",
                },
                input: {
                  "&::placeholder": {
                    textOverflow: "ellipsis !important",
                    color: "#6EB2F7",
                    opacity: "5",
                  },
                },
              }}
              inputProps={{
                style: {
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#031A30",
                  letterSpacing: "0.5px",
                  backgroundColor: "#F5FAFE",
                  borderRadius: "8px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIconText sx={{ color: "#6EB2F7" }} />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#F5FAFE",
                  borderRadius: "8px",
                },
              }}
              placeholder="Search by name"
              variant="outlined"
              value={props.searchAssignee}
              onChange={props.handleSearchAssignee}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>

          {props.filteredStaffList.map((cc: StaffDetail) => (
            <MenuItem
              value={cc.id}
              key={cc.id}
              sx={{
                borderBottom: "1px solid #E7F2FE",
                padding: "8px 16px 8px 9px !important",
              }}
            >
              <FormCheckbox
                checked={
                  !!props.selectedStaffIds &&
                  props.selectedStaffIds.length > 0 &&
                  !!props.selectedStaffIds.find((dd) => dd == cc.id)
                    ? true
                    : false
                }
              />
              <CalendarRotationTalentBox
                staffName={`${cc.first_name} ${cc.last_name}`}
                roleIds={cc.role_ids}
                showAvatar={true}
              />
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          width: "100%",
          color: "#737F8C",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LableTextFieldBox>
          <TextFieldLableTypography text="Agency name" required={false} />
        </LableTextFieldBox>
        <PlatformTextField
          sx={{
            width: "100%",
            "& fieldset": {
              border: "1px solid #F1F2F4 !important",
              borderRadius: "8px",
            },
            ".MuiInputBase-root .MuiOutlinedInput-input": {
              backgroundColor: "#F1F2F4 !important",
            },
          }}
          InputProps={{
            readOnly: true,
          }}
          value={props.agencyName}
          handleChange={function (e: any): void {}}
          placeholder={""}
        ></PlatformTextField>
      </Box>
    </Box>
  );
};

export default CalendarTalentForm;
