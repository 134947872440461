import { Box, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "../../icons/icon";
import { format } from "date-fns";
import { getDateRange } from "../../../../libs/common-lib";
import { useSearchShiftContext } from "../../../../libs/context-lib/shiftDetailContext";

interface ICalendarDateBox {
  showLeftIcon: boolean;
  showRightIcon: boolean;
  date: Date;
}

export const CalendarDateBox = (props: ICalendarDateBox) => {

  const {
    // searchShift, 
    // setSearchShift, 
    // dateRange, 
    setDateRange
  } = useSearchShiftContext();

  const handlePreviousDateClick = () => {
    let startDate = new Date(props.date);
    startDate = new Date(startDate.setDate(new Date(startDate).getDate() - 7));

    let endDate = new Date(props.date);
    endDate = new Date(endDate.setDate(new Date(endDate).getDate() - 1));

    let dateRangeList = getDateRange(startDate, endDate, 1);
    setDateRange(dateRangeList);

    // startDate = dateRangeList[0];
    // endDate = dateRangeList[6];

    // setSearchShift({
    //   ...searchShift,
    //   from_date: format(startDate, "MM-dd-yyyy"),
    //   to_date: format(endDate, "MM-dd-yyyy"),
    // });
  }

  const handleNextDateClick = () => {
    let startDate = new Date(props.date);
    startDate = new Date(startDate.setDate(new Date(startDate).getDate() + 1));

    let endDate = new Date(props.date);
    endDate = new Date(endDate.setDate(new Date(endDate).getDate() + 7));

    let dateRangeList = getDateRange(
      startDate,
      endDate,
      1
    );
    
    setDateRange(dateRangeList);
    // startDate = dateRangeList[0];
    // endDate = dateRangeList[6];

    // setSearchShift({
    //   ...searchShift,
    //   from_date: format(startDate, "MM-dd-yyyy"),
    //   to_date: format(endDate, "MM-dd-yyyy"),
    // });
  }

  return (
    <>
      <Box
        sx={{
          width: "calc(100% / 7)",
          height: "calc(100% - 50px)",
          display: "flex",
          background: format(props.date, "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd") ? "#E7F2FE" : "##CFE5FC" ,
          flexDirection: "row",
          borderRight: "1px solid #C4D9EF",
          borderBottom: "none !important",
          color: "#084C91",
          padding: "25px 0px 25px 0px",
        }}
      >
        <Box sx={{ width: "10%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={handlePreviousDateClick}>
          {props.showLeftIcon && (<ChevronLeft sx={{ width: "24px", height: "24px", color:"#0D80F2", cursor: "pointer" }} />)}
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "24.3px",
            }}
          >
            {format(props.date, "d MMMM")}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20.4px",
            }}
          >
            {format(props.date, "EEEE")}
          </Typography>
        </Box>
        <Box
          sx={{ width: "10%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={handleNextDateClick}
        >
           {props.showRightIcon && (<ChevronRight sx={{ width: "24px", height: "24px", color:"#0D80F2", cursor: "pointer" }} />)}
        </Box>
      </Box>
    </>
  );
};
