import { Box, MenuItem } from "@mui/material";
import {
  LableTextFieldBox,
  MultiSelectDropdown,
  PlatformTextField,
  Typography12_600,
  Typography14_600,
} from "../../atom";
import { useEffect, useLayoutEffect, useState } from "react";
import { AgencyDetail } from "../../../libs/model-lib/agencyDetail";
import { emptyAgencyDetail, getErrorDetail } from "../../../libs/common-lib";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import { getAddressValue } from "../../../libs/services-lib";
import { GenericMasterData } from "../../../libs/model-lib";
import {
  addressListState,
  jobQualificationListState,
  lineOfBusinessListState,
} from "../../../libs/state-management-lib";
import { JobQualification } from "../../../libs/model-lib/jobQualification";
import { agencyDetailState } from "../../../libs/state-management-lib/agencyState";

interface IStaffingPartnerContactForm {
  errorMessage: any[];
}

export const StaffingPartnerContactForm = (
  props: IStaffingPartnerContactForm
) => {
  const [agencyDetail, setAgencyDetail] =
    useState<AgencyDetail>(emptyAgencyDetail);
  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const [lineOfBusinessList, setLineOfBusinessList] = useState<
    GenericMasterData[]
  >(lineOfBusinessListState.getState().lineOfBusinessList);
  const [jobQualificationList, setJobQualificationList] = useState<
    JobQualification[]
  >(jobQualificationListState.getState().jobQualificationList);

  useLayoutEffect(() => {
    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    return () => {
      addressListStateSub();
    };
  }, []);

  useEffect(() => {
    setAgencyDetail(emptyAgencyDetail);
    agencyDetailState.setState(emptyAgencyDetail);
  }, []);

  useEffect(() => {
    agencyDetailState.setState(agencyDetail);
  }, [agencyDetail]);

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (event.target.value.length >= 3) {
      await getAddressValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setAgencyDetail({
        ...agencyDetail,
        address1: `${!!value.streetNumber ? value.streetNumber : ""} ${
          !!value.streetName ? value.streetName : ""
        }`,
        address2: "",
        city: `${!!value.municipality ? value.municipality : ""}`,
        state: `${!!value.countrySubdivision ? value.countrySubdivision : ""}`,
        postal_code: `${!!value.postalCode ? value.postalCode : ""}`,
      });
    }
  };

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const postalCode = inputValue.replace(/\D/g, "");
    if (postalCode.length <= 5) {
      setAgencyDetail({
        ...agencyDetail,
        postal_code: postalCode,
      });
    }
  };

  const handleLineOfBusinessChange = (e: any) => {
    setAgencyDetail({
      ...agencyDetail,
      line_of_business: e.target.value,
    });
  };

  const handleLineOfBusinessRemove = (e: any) => {
    setAgencyDetail({
      ...agencyDetail,
      line_of_business: agencyDetail.line_of_business.filter(
        (ln: number) => ln != e
      ),
    });
  };

  const handleJobQualificationChange = (e: any) => {
    setAgencyDetail({
      ...agencyDetail,
      job_qualifications: e.target.value,
    });
  };

  const handleJobQualificationRemove = (e: any) => {
    setAgencyDetail({
      ...agencyDetail,
      job_qualifications: agencyDetail.job_qualifications.filter(
        (ln: string) => ln != e
      ),
    });
  };

  return (
    <>
      <Box
        sx={{
          "& p": {
            color: "#17191C !important",
          },
        }}
      >
        <Typography14_600 text={"Agency detail"} />
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexdirection: "row",
          gap: "16px",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Contact email" color="#737F8C" required />
            <PlatformTextField
              placeholder={"Enter contact email"}
              value={agencyDetail.contact_person_details.email}
              handleChange={(e: any) => {
                setAgencyDetail({
                  ...agencyDetail,
                  contact_person_details: {
                    ...agencyDetail.contact_person_details,
                    email: e.target.value,
                  },
                });
              }}
              errorMessage={getErrorDetail(
                "contact_person_details$email",
                props.errorMessage
              )}
            />
          </LableTextFieldBox>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexdirection: "row",
          gap: "16px",
        }}
      >
        <Box sx={{ width: "50%", display: "flex", flexdirection: "row" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Agency address 1" color="#737F8C" />
            <AutoCompleteTextInput
              disabled
              value={agencyDetail.address1}
              onChange={handleAutocompleteChange}
              onInputChange={(event: any, newInputValue: any) => {
                handleAddressAutopopulate(event, newInputValue);
              }}
              options={addressValue}
              getOptionLabel={(option: any) =>
                typeof option === "string" ? option : option.freeformAddress
              }
              filterOptions={(x: any) => x}
              noOptionsText="No locations"
              label={""}
              placeholder={"Enter agency address 1"}
              errorMessage={getErrorDetail(
                "address1",
                props.errorMessage
              )}
            />
          </LableTextFieldBox>
        </Box>
        <Box sx={{ width: "50%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Agency address 2" color="#737F8C" />
            <PlatformTextField
              disabled
              placeholder={"Enter agency address 2"}
              value={agencyDetail.address2}
              handleChange={(e: any) => {
                setAgencyDetail({
                  ...agencyDetail,
                  address2: e.target.value,
                });
              }}
            />
          </LableTextFieldBox>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexdirection: "row",
          gap: "16px",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="City" color="#737F8C" />
            <PlatformTextField
              disabled
              placeholder={"Enter city"}
              value={agencyDetail.city}
              handleChange={(e: any) => {
                setAgencyDetail({
                  ...agencyDetail,
                  city: e.target.value,
                });
              }}
              errorMessage={getErrorDetail(
                "address2",
                props.errorMessage
              )}
            />
          </LableTextFieldBox>
        </Box>
        <Box sx={{ width: "25%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="State" color="#737F8C" />
            <PlatformTextField
              disabled
              placeholder={"Enter state"}
              value={agencyDetail.state}
              handleChange={(e: any) => {
                setAgencyDetail({
                  ...agencyDetail,
                  state: e.target.value,
                });
              }}
              errorMessage={""}
            />
          </LableTextFieldBox>
        </Box>
        <Box sx={{ width: "25%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Postal code" color="#737F8C" />
            <PlatformTextField
              disabled
              placeholder={"Enter postal code"}
              value={agencyDetail.postal_code}
              handleChange={handlePostalcodeChange}
              errorMessage={""}
            />
          </LableTextFieldBox>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexdirection: "row",
          gap: "16px",
        }}
      >
        <Box sx={{ width: "50%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Line of business" color="#737F8C" />
            <MultiSelectDropdown
              sx={{
                "&.Mui-disabled": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ABB2BA !important",
                  },
                },
              }}
              disabled
              placeholder="Select line of business"
              handleChange={handleLineOfBusinessChange}
              value={agencyDetail.line_of_business}
              label={""}
              listItems={lineOfBusinessList}
              handleRemove={handleLineOfBusinessRemove}
            >
              {lineOfBusinessList.map((cc: GenericMasterData) => (
                <MenuItem value={cc.id} key={cc.id}>
                  {cc.name}
                </MenuItem>
              ))}
            </MultiSelectDropdown>
          </LableTextFieldBox>
        </Box>
        <Box sx={{ width: "50%" }}>
          <LableTextFieldBox>
            <Typography12_600 text="Job qualification" color="#737F8C" />
            <MultiSelectDropdown
              sx={{
                "&.Mui-disabled": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ABB2BA !important",
                  },
                },
              }}
              disabled
              placeholder="Select job qualification"
              handleChange={handleJobQualificationChange}
              value={agencyDetail.job_qualifications}
              label={""}
              listItems={jobQualificationList}
              handleRemove={handleJobQualificationRemove}
              labelValue="abbreviation"
            >
              {jobQualificationList.map((cc: JobQualification) => (
                <MenuItem value={cc.id} key={cc.id}>
                  {cc.abbreviation}
                </MenuItem>
              ))}
            </MultiSelectDropdown>
          </LableTextFieldBox>
        </Box>
      </Box>
    </>
  );
};
