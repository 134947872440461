import { Avatar, Box } from "@mui/material";
import { ShareShiftCheckBox } from "../../checkbox/shareShiftCheckbox";
import { AgencyDetail } from "../../../../libs/model-lib/agencyDetail";
import { Typography14_500 } from "../../typography";
import { useShareOpenShiftsContext } from "../../../../libs/context-lib/shiftDetailContext";
interface ICalendarShareshiftAgencyBox {
  agencyDetail: AgencyDetail;
  src: string;
}
export const CalendarShareshiftAgencyBox = (
  props: ICalendarShareshiftAgencyBox
) => {
  const { shareOpenShifts, setShareOpenShifts } = useShareOpenShiftsContext();

  const handelSelectedAgency = (event: any) => {
    let selectedAgencyList = shareOpenShifts.agency_ids.slice(0);

    if (event.target.checked) {
      selectedAgencyList.push(event.target.value);
    } else {
      selectedAgencyList = selectedAgencyList.filter(
        (cc) => cc != event.target.value
      );
    }

    setShareOpenShifts({
      ...shareOpenShifts,
      agency_ids: selectedAgencyList,
    });
  };

  const checkCheckBoxStatus = () => {
    let checked = false;

    if (!!shareOpenShifts.agency_ids && shareOpenShifts.agency_ids.length > 0) {
      if (
        shareOpenShifts.agency_ids.find(
          (cc: string) => cc === props.agencyDetail.id
        )
      ) {
        checked = true;
      }
      else {
        checked = false;
      }
    }

    return checked;
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "52px",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "5px",
          borderRadius: "5px 0px 4px 4px",
          borderBottom: "1px solid #E3E5E8",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <ShareShiftCheckBox
          value={props.agencyDetail.id}
          onChange={handelSelectedAgency}
          checked={checkCheckBoxStatus()}
        />
        <Avatar
          variant="square"
          src={props.src}          
          sx={{
            height: "40px",
            width: "40px",
            fontSize: "18px",
            borderRadius: "10px",
            fontWeight: "500",
            letterSpacing: "1px",
          }}
        />
        <Typography14_500 text={props.agencyDetail.name} color="#17191C" />
      </Box>
    </>
  );
};
