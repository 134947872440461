import { Box, Typography } from "@mui/material";

interface ITextAreaField {
  Label: string;
  handleChange: (e: any) => void;
  value: string;
  disabled?: boolean;
  errorMessage?: any;
}

export const TextAreaField = (props: ITextAreaField) => {
  return (
    <Typography
      sx={{
        ".MuiTypography-root": {
          fontFamily: "Roboto",
        },
      }}
    >
      <textarea
        style={{
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "24px",
          boxSizing: "border-box",
          width: "100%",
          background: "#F5FAFE",
          border: !!props.errorMessage ? "1px solid red" :  "1px solid #CFE6FC",
          borderRadius: "8px",
          outline: 0,
          fontFamily: "Roboto !important",
        }}
        className="textarea"
        placeholder={props.Label}
        rows={4}
        value={props.value}
        onChange={props.handleChange}
        disabled={!!props.disabled && props.disabled ? true : false}
      ></textarea>
      {!!props.errorMessage && (
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22.4px",
            color: "red",
          }}
          className="red-text"
        >
          {props.errorMessage}
        </Box>
      )}
    </Typography>
  );
};
