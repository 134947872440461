import { axiosInstance, emptyRoleDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import { OrganizationRole } from "../model-lib";
import {
  roleDetailState,
  roleListState,
} from "../state-management-lib";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/OrganizationRole`;
export const getRoleByOrganizationId = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetRoleByOrganizationId?organizationId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      roleListState.setState({
        roleList: response.data.data,
      });
    }
  } catch (error: any) {}
};

export const createRole = async (organizationRole: OrganizationRole) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateRole`,
      organizationRole
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      handleApiSuccessAlert("Role created successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const getOrganizationRoleById = async (roleId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetOrganizationRoleById?id=${roleId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      roleDetailState.setState(response.data.data);
      return response.data.data;
    }

    return emptyRoleDetail;
  } catch (error: any) {}
};

export const updateRole = async (organizationRole: OrganizationRole) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateRole`,
      organizationRole
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getRoleByOrganizationId(organizationRole.org_id);
      handleApiSuccessAlert("Role updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
