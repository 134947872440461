import { Box, MenuItem } from "@mui/material";
import {
  DropdownWithBorder,
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { useLayoutEffect, useState } from "react";
import {
  GenericMasterData,
  LocationDetail,
} from "../../../libs/model-lib";
import {
  addressListState,
  departmentClearedState,
  departmentTypeListState,
  loadingIconState,
  locationDetailListState,
  locationDetailState,
} from "../../../libs/state-management-lib";
import {  EmptyLocationDetail, getErrorDetail } from "../../../libs/common-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { getAddressValue, getLocationById } from "../../../libs/services-lib";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import { useDepartmentDetailContext } from "../../../libs/context-lib/departmentDetailContext";

interface IDepartmentAddressForm {
  errorMessage: any[];
}
export const DepartmentAddressForm = (props: IDepartmentAddressForm) => {
  const { departmentDetail, setDepartmentDetail } =
    useDepartmentDetailContext();

  const [depDetailList, setDepDetailList] = useState<GenericMasterData[]>(
    departmentTypeListState.getState().departmentTypeList
  );
  const [locDetailList, setLocDetailList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );

  const [locationDetail, setLocationDetail] = useState<LocationDetail>(
    locationDetailState.getState()
  );

  const [addressValue, setAddressValue] = useState<readonly any[]>([]);
  const [sameAddress, setSameAddress] = useState<boolean>(false);

  useLayoutEffect(() => {
    const locationDetailStateSub = locationDetailState.subscribe(
      async (state) => {
        setLocationDetail(state);
      }
    );

    const addressListStateSub = addressListState.subscribe(async (state) => {
      setAddressValue(state.addressList);
    });

    const departmentClearedStateSub = departmentClearedState.subscribe(
      async (state) => {
        setSameAddress(false);
      }
    );

    return () => {
      locationDetailStateSub();
      addressListStateSub();
      departmentClearedStateSub();
    };
  }, []);

  const handleLocationChange = async (event: any) => {
    setDepartmentDetail({
      ...departmentDetail,
      location_Id: event.target.value,
    });
    loadingIconState.setState(true);
    await getLocationById(event.target.value);
    loadingIconState.setState(false);
  };

  const handleChecked = (e: any) => {
    if (e.target.checked) {
      setDepartmentDetail({
        ...departmentDetail,
        address_1: locationDetail.address_1,
        address_2: locationDetail.address_2,
        state: locationDetail.state,
        city: locationDetail.city,
        zip_code: locationDetail.postal_code,
      });
      setSameAddress(true);
    } else {
      setDepartmentDetail({
        ...departmentDetail,
        address_1: "",
        address_2: "",
        state: "",
        city: "",
        zip_code: "",
      });
      setSameAddress(false);
    }
  };

  const handlePostalcodeChange = (event: any) => {
    const inputValue = event.target.value;
    const zipCode = inputValue.replace(/\D/g, "");
    if (zipCode.length <= 5) {
      setDepartmentDetail({
        ...departmentDetail,
        zip_code: zipCode,
      });
    }
  };

  const handleAddressAutopopulate = async (event: any, value: any) => {
    if (event.target.value.length >= 3) {
      await getAddressValue(event.target.value);
    }
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setDepartmentDetail({
        ...departmentDetail,
        address_1: `${!!value.streetNumber ? value.streetNumber : ""} ${
          !!value.streetName ? value.streetName : ""
        }`,
        address_2: "",
        city: `${!!value.municipality ? value.municipality : ""}`,
        state: `${!!value.countrySubdivision ? value.countrySubdivision : ""}`,
        zip_code: `${!!value.postalCode ? value.postalCode : ""}`,
      });
    }
  };

  const handleClearDepartmentType = ()=>{
    setDepartmentDetail({
      ...departmentDetail,
      department_type: 0,
    });
  }

  const handleClearLocation = ()=>{
    setDepartmentDetail({
      ...departmentDetail,
      location_Id: "",
    });
    setLocationDetail(EmptyLocationDetail)
  }

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Department details" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter department name"}
                  value={departmentDetail.name}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("name", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Department type"
                  color="#737F8C"
                  required
                />
                <DropdownWithBorder
                  value={departmentDetail.department_type}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      department_type: e.target.value,
                    });
                  }}
                  handleClear={handleClearDepartmentType}
                  errorMessage={getErrorDetail(
                    "department_type",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={0} disabled>Select department type</MenuItem>

                  {depDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Location" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600
                  text="Location"
                  color="#737F8C"
                  required
                  infoIcon
                  toolTip={
                    "A department has been created in the chosen location."
                  }
                />
                <DropdownWithBorder
                  value={departmentDetail.location_Id}
                  handleChange={handleLocationChange}
                  handleClear={handleClearLocation}
                  errorMessage={getErrorDetail(
                    "location_Id",
                    props.errorMessage
                  )}
                >
                  <MenuItem value={""} disabled>Select location</MenuItem>
                  {locDetailList.map((cc) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      <Typography16_400 text={cc.name} color="#031A30" />
                    </MenuItem>
                  ))}
                </DropdownWithBorder>
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Department location" color="#737F8C" />
                <PlatformTextField
                  placeholder={"e.g. located at the 4th floor"}
                  value={departmentDetail.department_location}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      department_location: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "department_location",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Address" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              onChange={handleChecked}
              inputProps={{ "aria-label": "controlled" }}
              checked={sameAddress}
            />
            <Typography16_400 text="Same as location address" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Address 1" color="#737F8C" required />
                <AutoCompleteTextInput
                  value={departmentDetail.address_1}
                  onChange={handleAutocompleteChange}
                  onInputChange={(event: any, newInputValue: any) => {
                    handleAddressAutopopulate(event, newInputValue);
                    setDepartmentDetail({
                      ...departmentDetail,
                      address_1: event.target.value,
                    });
                    setSameAddress(false);
                  }}
                  options={addressValue}
                  getOptionLabel={(option: any) =>
                    typeof option === "string" ? option : option.freeformAddress
                  }
                  filterOptions={(x: any) => x}
                  noOptionsText="No locations"
                  label={""}
                  placeholder={"Enter address 1"}
                  errorMessage={getErrorDetail("address_1", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Address 2" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter address 2"}
                  value={departmentDetail.address_2}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      address_2: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("address_2", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="City" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter city"}
                  value={departmentDetail.city}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      city: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("city", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="State" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter state"}
                  value={departmentDetail.state}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      state: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail("state", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "25%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Postal code" color="#737F8C" required />
                <PlatformTextField
                  placeholder={"Enter postal code"}
                  value={departmentDetail.zip_code}
                  handleChange={handlePostalcodeChange}
                  errorMessage={getErrorDetail("zip_code", props.errorMessage)}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
