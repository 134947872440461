import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";
import { SettingsTemplate } from "../template/settingsTemplate";

export const Setting = () => {
  useEffect(() => {
    if (window.location.pathname.includes("/settings")) {
      currentActiveScreenState.setState("/settings/organization");
    } else {
      currentActiveScreenState.setState("/talents");
    }
  }, []);

  return (
    <>
      <SettingsTemplate />
    </>
  );
};
