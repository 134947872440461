import { Avatar, Box, Typography } from "@mui/material";
import { Typography10_600, Typography14_600 } from "../../typography";
import { StaffDetail } from "../../../../libs/model-lib";
import { orgRoleListState } from "../../../../libs/state-management-lib";
import { convertMinutestoHour } from "../../../../libs/common-lib";

interface ICalendarStaffDetailBox {
  staffDetail: StaffDetail;
}

export const CalendarStaffDetailBox = (props: ICalendarStaffDetailBox) => {

  const getRoleName = (roleIds: number[]) => {
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  const checkShowAgency = () => {
    if (
      props.staffDetail.staff_type === 103
    ) {
      return true;
    }

    if (
      props.staffDetail.staff_type === 104
    ) {
      return true;
    }

    return false;
  }


  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          minHeight: "88px",
        }}
      >
        <Box
          sx={{
            width: "calc(100% - 16px)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "8px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
            border: "1px solid #CFE6FC",
            borderRadius: "8px",
            gap: "8px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Avatar
              sx={{ width: "40px", height: "40px", borderRadius: "8px" }}
              variant="square"
            />
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography14_600
                  text={`${props.staffDetail.first_name} ${props.staffDetail.last_name}`}
                  color="#17191C"
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "10px", color: "#737F8C" }}>
                  {getRoleName(props.staffDetail.role_ids)}
                </Typography>
                <Typography sx={{ fontSize: "10px", color: "#737F8C" }}>
                  {convertMinutestoHour(
                    !!props.staffDetail.staffWorkingHours
                      ? props.staffDetail.staffWorkingHours
                      : 0
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          {checkShowAgency() && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                alignItems: "center",
              }}
            >
              {props.staffDetail.staff_type === 103 &&
              !!props.staffDetail.agencyDetails ? (
                <>
                  <Avatar
                    // {...stringAvatar(
                    //   !!props.staffDetail.agencyDetails
                    //     ? props.staffDetail.agencyDetails?.name
                    //     : ""
                    // )}
                    src={props.staffDetail.agencyDetails.logo_url} 
                    sx={{
                      width: "24px",
                      height: "24px",
                      background: "#FFFFFF",
                      color: "#ABB2BA",
                      border: "1px solid #E3E5E8",
                      borderRadius: "8px",
                      fontSize: "14px",
                    }}
                    variant="square"
                  />
                  <Typography10_600
                    text={props.staffDetail.agencyDetails?.name}
                    color="#ABB2BA"
                  />
                </>
              ) : (
                <>
                  <Avatar
                    sx={{
                      width: "24px",
                      height: "24px",
                      background: "#FFFFFF",
                      color: "#ABB2BA",
                      border: "1px solid #E3E5E8",
                      borderRadius: "8px",
                      fontSize: "14px",
                    }}
                    variant="square"
                  />
                  <Typography10_600 text="Contractor" color="#ABB2BA" />
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
