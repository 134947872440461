import { axiosInstance, emptyUnitDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import { UnitDetail } from "../model-lib";
import {
  organizationDetailState,
  orgUnitDetailListState,
  unitDetailState,
  unitListState,
} from "../state-management-lib";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/SubDepartment`;

export const createUnit = async (unitDetail: UnitDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateSubDepartment`,
      unitDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getSubDepartmentList();
      handleApiSuccessAlert("Sub-department created successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const updateUnit = async (unitDetail: UnitDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateSubDepartment`,
      unitDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getSubDepartmentList();
      handleApiSuccessAlert("Sub-department updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const getSubDepartmentList = async () => {
  let pageSize = {
    pageSize: 50,
    pageNumber: 1,
    orgId: organizationDetailState.getState().id,
  };
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/list`,
      pageSize
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      unitListState.setState({
        unitDetailList: response.data.data.results,
      });
    }
  } catch (error) {}
};

export const getSubDepartmentById = async (unitId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/Id?subDepartmentId=${unitId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      unitDetailState.setState(response.data.data);
      return response.data.data;
    }
    
    return emptyUnitDetail;
  } catch (error) {}
};

export const getSubDepartmentByDepartmentId = async (departmentId: any) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/departmentId?departmentId=${departmentId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const getSubDepartmentByLocationId = async (locationId: any) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetSubDepartmentsByLocationtId?locationId=${locationId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      return response.data.data;
    }

    return [];
  } catch (error) {}
};

export const getSubDepartmentByOrgId = async (orgId: any) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetSubDepartmentsByOrgId?orgId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      orgUnitDetailListState.setState({
        orgUnitDetailList: response.data.data,
      });
      return response.data.data;
    }

    return [];
  } catch (error) {}
};
