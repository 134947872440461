import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemButton,
  Menu,
} from "@mui/material";
import {
  ActiveConfirmationPopup,
  ActiveIcon,
  ContainedButton,
  ContainedDeleteButton,
  InActiveConfirmationPopup,
  LocationGridIcon,
  OutlinedButton,
  Typography14_400,
  Typography16_400,
  Typography16_600,
  Typography32_600,
} from "../../atom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useLayoutEffect, useState } from "react";
import { DepartmentDetail } from "../../../libs/model-lib";
import {
  activeConfirmationPopupState,
  confirmationPopupTitleState,
  departmentListState,
  inActiveConfirmationPopupState,
  locationDetailListState,
} from "../../../libs/state-management-lib";
import {
  DataGrid,
} from "@mui/x-data-grid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { any } from "joi";
import { IosSwitch } from "../../atom/Switch/iosSwitch";

interface ILocationDashboard {
  handleLocationDashboardCallBack: (locId: string) => void;
  handleActiveLocation: (activeLocationId: string) => void;
  handleInActiveLocation: (inActiveLocationId: string) => void;
  handleAddLocation: () => void;
}
export const LocationDashboard = (props: ILocationDashboard) => {
  const [locDetailList, setLocDetailList] = useState<any[]>(
    locationDetailListState.getState().locationDetailList
  );
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [locationGridData, setLocationGridData] = useState<any>([]);
  const [locActionState, setLocActionState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [activeChecked, setActiveChecked] = useState<boolean>(false);
  const [activeLocationId, setActiveLocationId] = useState("");
  const [showActiveConfirmationPopup, setshowActiveConfirmationPopup] =
    useState(false);
  const [showInActiveConfirmationPopup, setshowInActiveConfirmationPopup] =
    useState(false);

  useLayoutEffect(() => {
    const locationDetailListStateSub = locationDetailListState.subscribe(
      async (state) => {
        setLocDetailList(state.locationDetailList);
      }
    );

    const departmentListStateSub = departmentListState.subscribe(
      async (state) => {
        setDepartmentList(state.departmentDetailList);
      }
    );

    const inActiveConfirmationPopupStateSub =
      inActiveConfirmationPopupState.subscribe(async (state) => {
        setshowInActiveConfirmationPopup(state);
      });

    const activeConfirmationPopupStateSub =
      activeConfirmationPopupState.subscribe(async (state) => {
        setshowActiveConfirmationPopup(state);
      });

    return () => {
      locationDetailListStateSub();
      departmentListStateSub();
      inActiveConfirmationPopupStateSub();
      activeConfirmationPopupStateSub();
    };
  }, []);

  useEffect(() => {
    refreshEvent();
  }, []);

  const handleEditLocationClick = async (locId: any) => {
    props.handleLocationDashboardCallBack(locId.id);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, params: any) => {
    setLocActionState({ anchorEl: event.currentTarget, rowId: params.id });
    setActiveChecked(params.row.status);
  };

  const handleActiveClick = (params: any) => {
    if (activeChecked) {
      activeConfirmationPopupState.setState(true);
    } else {
      inActiveConfirmationPopupState.setState(true);
    }
    let locationId = params.id.toString();
    setActiveLocationId(locationId);
    let locationName = params.row.name.toString();
    confirmationPopupTitleState.setState(locationName);
    setLocActionState({ anchorEl: null, rowId: null });
  };

  const handleActiveLocation = () => {
    props.handleActiveLocation(activeLocationId);
  };

  const handleInActiveLocation = () => {
    props.handleInActiveLocation(activeLocationId);
  };

  const handleClose = () => {
    setLocActionState({ anchorEl: null, rowId: null });
  };

  const handleCloseClick = () => {
    activeConfirmationPopupState.setState(false);
  };

  const handleInactivePopupClick = () => {
    inActiveConfirmationPopupState.setState(false);
  };

  const refreshEvent = () => {
    const modifiedData = locDetailList.map((cc) => ({
      id: cc.id,
      name: cc.name,
      status: cc.is_active,
      unitsCount: 0,
    }));
    setLocationGridData(modifiedData);
  };

  const rows = locDetailList.map((cc) => ({
    id: cc.id,
    name: cc.name,
    status: cc.is_active,
    unitsCount: 0,
    actions: any,
  }));

  const handleAddLocation = () => {
    props.handleAddLocation();
  };

  return (
    <>
      {locDetailList.length <= 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            marginTop: "6%",
          }}
        >
          <SearchIcon
            fontSize="large"
            sx={{ color: "rgba(61, 153, 245, 0.5)" }}
          />
          <Typography32_600
            text="No active location"
            color="rgba(61, 153, 245, 0.5)"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  cursor: "pointer",
                }}
                onClick={handleAddLocation}
              >
                <AddIcon fontSize="medium" sx={{ color: "#0D80F2" }} />
                <Typography16_600 text="Create from scratch" color="#0D80F2" />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Create location from scratch, providing"
                  color="#8F98A3"
                />
                <Typography16_400 text="mandatory details." color="#8F98A3" />
              </Box>
            </Box>

            <Divider
              sx={{
                borderColor: "#CFE5FC",
              }}
              orientation="vertical"
              flexItem
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #ABB2BA",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  backgroundColor:"#F1F2F4"
                }}
              >
                <AddIcon fontSize="medium" sx={{ color: "#8F98A3" }} />
                <Typography16_600 text="Start with template" color="#8F98A3" />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Create locations using a template with"
                  color="#8F98A3"
                />
                <Typography16_400
                  text="placeholder details and edit the letter as needed."
                  color="#8F98A3"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {locDetailList.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "6px",
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "8px",
                width: "100%",
                height: "90%",
              }}
            >
              <DataGrid
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .theme--header": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #E3E5E8",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F5FAFE",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    background: "#F9FAFA",
                  },
                  "& .MuiDataGrid-columnHeaderTitle" : {
                    fontWeight:"600",
                    color:"rgba(115, 127, 140, 1)"
                  },
                  "& .MuiCheckbox-root": {
                    color: "#ABB2BA !important",
                  },
                  "& .Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  "& .MuiDataGrid-scrollbarFiller": {
                    backgroundColor: "#F9FAFA",
                  },
                  border: "1px solid #CFE5FC",
                }}
                key={locationGridData.id}
                onRowClick={handleEditLocationClick}
                rows={rows}
                columns={[
                  {
                    field: "name",
                    headerName: "Locations",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: true,
                    renderCell: (params: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 4px",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #E3E5E8",
                              borderRadius: "8px",
                              height: "32px",
                              width: "32px",
                            }}
                          >
                            <LocationGridIcon
                              sx={{
                                color: "#17191C",
                              }}
                            />
                          </Box>
                          <Typography14_400
                            text={params.row.name}
                            color="#0D80F2"
                          />
                        </Box>
                      );
                    },
                  },
                  {
                    field: "departmentCount",
                    headerName: "Departments",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    valueGetter: (value, row) =>
                      `${
                        departmentList.filter((ee) => ee.location_Id == row.id)
                          .length
                      } `,
                  },
                  // {
                  //   field: "unitsCount",
                  //   headerName: "Sub-departments",
                  //   headerClassName: "theme--header",
                  //   flex: 1,
                  //   sortable: false,
                  // },
                  {
                    field: "status",
                    headerName: "Status",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    renderCell: (params: any) => {
                      return (
                        <Chip
                          label={params.row.status ? "Active" : "Inactive"}
                          sx={{
                            height: "22px",
                            background: params.row.status
                              ? "#F7FDF8"
                              : "#ffece5",
                            border: "1px solid #EBFAEE",
                            borderRadius: "4px",
                            color: params.row.status ? "#29A347" : "#ff5722",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        />
                      );
                    },
                  },
                  {
                    field: "actions",
                    type: "actions",
                    headerClassName: "theme--header",
                    cellClassName: "actions",
                    align: "right",
                    width: 50,

                    // renderHeader: (params: any) => (
                    //   <Box
                    //     sx={{
                    //       width: "100%",
                    //       display: "flex",
                    //       flexDirection: "row",
                    //       justifyContent: "flex-end",
                    //     }}
                    //     key={`action-${params.id}`}
                    //   >
                    //     <Box
                    //       sx={{ color: "#0D80F2" }}
                    //       onClick={(event) => handleClick(event, params)}
                    //     >
                    //       <IconButton
                    //         sx={{
                    //           background: "#FFFFFF",
                    //           color: "#0D80F2",
                    //           borderRadius: "10px !important",
                    //         }}
                    //       >
                    //         <MoreHorizIcon />
                    //       </IconButton>
                    //     </Box>
                    //   </Box>
                    // ),
                    renderCell: (params: any) => {
                      const isOpen = locActionState.rowId === params.id;
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                          key={`action-${params.id}`}
                        >
                          <Box
                            sx={{ color: "#0D80F2" }}
                            onClick={(event) => handleClick(event, params)}
                          >
                            <IconButton
                              sx={{
                                background: "#FFFFFF",
                                color: "#0D80F2",
                                borderRadius: "10px !important",
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Box>
                          <Menu
                            anchorEl={locActionState.anchorEl}
                            id="account-menu"
                            open={isOpen}
                            onClose={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                width: "228px",
                                borderRadius: "8px",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "bstaffk",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-1-${params.id}`}
                              onClick={() => {}}
                            >
                              <DeleteIcon sx={{ color: "#0D80F2" }} />{" "}
                              <Typography16_400 text="Delete" color="#031A30" />
                            </ListItemButton> */}
                            <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-2-${params.id}`}
                              onClick={() => handleActiveClick(params)}
                            >
                              <ActiveIcon sx={{ color: "#0D80F2" }} />
                              <Typography16_400 text="Active" color="#031A30" />
                              <Box sx={{ marginLeft: "35%" }}>
                                <IosSwitch checked={activeChecked} />
                              </Box>
                            </ListItemButton>
                          </Menu>
                        </Box>
                      );
                    },
                  },
                ]}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            </Box>
          </Box>
          <ActiveConfirmationPopup
            title={`Deactivate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to deactivate “${confirmationPopupTitleState.getState()}” location?`}
            open={showActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleCloseClick}
              />
              <ContainedDeleteButton
                sx={{ height: "40px"}}
                text="Deactivate location"
                handleClick={handleActiveLocation}
              />
            </Box>
          </ActiveConfirmationPopup>
          <InActiveConfirmationPopup
            title={`Activate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to activate “${confirmationPopupTitleState.getState()}” location?`}
            open={showInActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleInactivePopupClick}
              />
              <ContainedButton
                sx={{ height: "40px" }}
                text="Activate location"
                handleClick={handleInActiveLocation}
              />
            </Box>
          </InActiveConfirmationPopup>
        </>
      )}
    </>
  );
};
