import { Box, FormControlLabel, Switch } from "@mui/material";
import { grey } from "@mui/material/colors";
import {
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PlusIcon } from "../../atom";
import {
  ShiftSeriesDayForm,
  ShiftSeriesTimeForm,
} from "../../../libs/model-lib";
import { Dayjs } from "dayjs";

interface ICalendarSeriesShiftDayTime {
  day: string;
  dayNumber: number;
  shiftSeriesDayForm: ShiftSeriesDayForm[];
  handleToggleClick: (day: string, e: any) => void;
  handleSeriesStartTimeChange: (
    dayNumber: number,
    startTimeValue: Dayjs | null,
    timeIndex: number
  ) => void;
  handleSeriesEndTimeChange: (
    dayNumber: number,
    endTimeValue: Dayjs | null,
    timeIndex: number
  ) => void;
  handleAddSeriesClick: (dayNumber: number) => void;
  handleRemoveSeriesClick: (dayNumber: number) => void;
  handleCopySeriesClick: (dayNumber: number) => void;
}

export const CalendarSeriesShiftDayTime = (
  props: ICalendarSeriesShiftDayTime
) => {
  return (
    <>
      <Box
        sx={{
          width: "98%",
          height: "calc(56px-16px)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          background: "#FFFFFF",
          gap: "8px",
          padding: "8px",
        }}
      >
        <Box
          sx={{
            width: "30%",
          }}
        >
          <FormControlLabel
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              paddingLeft: "2%",
              gap: "10px",
              ".MuiFormControlLabel-label": {
                fontSize: "16px !important",
              },
            }}
            control={
              <Switch
                sx={{
                  width: "58px",
                  ".Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  ".Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#0D80F2 !important",
                  },
                }}
                checked={
                  !!props.shiftSeriesDayForm.find(
                    (cc) => cc.dayNumber == props.dayNumber
                  )
                    ? true
                    : false
                }
                onClick={(e: any) => {
                  props.handleToggleClick(props.day, e);
                }}
              />
            }
            label={props.day}
          />
        </Box>

        <Box
          sx={{
            width: "calc(98% - 30%)",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {!!props.shiftSeriesDayForm.find(
            (cc) => cc.dayNumber == props.dayNumber
          ) &&
            props.shiftSeriesDayForm
              .find((dd) => dd.dayNumber == props.dayNumber)
              ?.shiftSeriesTimeForm.map(
                (seriesTimeForm: ShiftSeriesTimeForm, index: number) => (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                    key={index}
                  >
                    <Box sx={{ width: "40%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          sx={{
                            justifyContent: "center",
                            background: "#F5FAFE",
                            width: "100%",
                            borderRadius: "8px",
                            "& .MuiSvgIcon-root": {
                              color: "#0D80F2 !important",
                            },
                            ".MuiInputBase-input": {
                              padding: "8.5px 14px !important",
                              fontSize: "16px",
                              fontWeight: "400",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #0D80F2 !important",
                              borderRadius: "8px",
                            },
                            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "2px solid #0D80F2 !important",
                              borderRadius: "8px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CFE5FC",
                              borderRadius: "8px",
                            },
                            "& .Mui-disabled .MuiSvgIcon-root": {
                              color: "#B6B9BC !important",
                            },

                            "& .Mui-disabled": {
                              background: "#F1F2F4 !important",
                              borderRadius: "8px",
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #ABB2BA !important",
                                borderRadius: "8px",
                              },
                            },

                            "& .MuiInputLabel-root": { display: "none" },
                            Input: {
                              "&::placeholder": {
                                color: "#6EB2F7",
                                opacity: "5",
                              },
                            },
                          }}
                          onChange={(newValue) => {
                            props.handleSeriesStartTimeChange(
                              props.dayNumber,
                              newValue,
                              index
                            );
                          }}
                          minutesStep={5}
                          slotProps={{
                            field: { clearable: true },
                            layout: {
                              sx: {
                                ul: {
                                  "&::-webkit-scrollbar, & *::-webkit-scrollbar":
                                    {
                                      width: "6px",
                                      height: "6px",
                                    },
                                  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                                    {
                                      borderRadius: "32px",
                                      backgroundColor: grey["400"],
                                    },
                                  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                                    {
                                      backgroundColor: "#0000000",
                                    },
                                },
                              },
                            },
                            textField: {
                              inputProps: {
                                readOnly: true,
                              },
                              InputProps: {
                                sx: {
                                  "& .MuiInputBase-input": {
                                    padding: "8px 0px 8px 8px !important",
                                  },
                                },
                              },
                              placeholder: "Start time",
                            },
                            clearButton: {
                              sx: {
                                padding: "0px !important",
                              },
                            },
                          }}
                          disabled={
                            !!props.shiftSeriesDayForm.find(
                              (cc) => cc.dayNumber == props.dayNumber
                            )
                              ? false
                              : true
                          }
                          value={seriesTimeForm.shiftStartTime}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ width: "40%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                          sx={{
                            justifyContent: "center",
                            background: "#F5FAFE",
                            width: "100%",
                            borderRadius: "8px",
                            "& .MuiSvgIcon-root": {
                              color: "#0D80F2 !important",
                            },
                            ".MuiInputBase-input": {
                              padding: "8.5px 14px !important",
                              fontSize: "16px",
                              fontWeight: "400",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #0D80F2 !important",
                              borderRadius: "8px",
                            },
                            ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "2px solid #0D80F2 !important",
                              borderRadius: "8px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CFE5FC",
                              borderRadius: "8px",
                            },
                            "& .Mui-disabled .MuiSvgIcon-root": {
                              color: "#B6B9BC !important",
                            },

                            "& .Mui-disabled": {
                              background: "#F1F2F4 !important",
                              borderRadius: "8px",
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #ABB2BA !important",
                                borderRadius: "8px",
                              },
                            },
                            "& .MuiInputLabel-root": { display: "none" },
                            Input: {
                              "&::placeholder": {
                                color: "#6EB2F7",
                                opacity: "5",
                              },
                            },
                          }}
                          onChange={(newValue) => {
                            props.handleSeriesEndTimeChange(
                              props.dayNumber,
                              newValue,
                              index
                            );
                          }}
                          minutesStep={5}
                          slotProps={{
                            field: { clearable: true },
                            layout: {
                              sx: {
                                ul: {
                                  "&::-webkit-scrollbar, & *::-webkit-scrollbar":
                                    {
                                      width: "6px",
                                      height: "6px",
                                    },
                                  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                                    {
                                      borderRadius: "32px",
                                      backgroundColor: grey["400"],
                                    },
                                  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                                    {
                                      backgroundColor: grey["500"],
                                    },
                                  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                                    {
                                      backgroundColor: "#0000000",
                                    },
                                },
                              },
                            },
                            textField: {
                              inputProps: {
                                readOnly: true,
                              },
                              InputProps: {
                                sx: {
                                  "& .MuiInputBase-input": {
                                    padding: "8px 0px 8px 8px !important",
                                  },
                                },
                              },
                              placeholder: "End time",
                            },
                            clearButton: {
                              sx: {
                                padding: "0px !important",
                              },
                            },
                          }}
                          disabled={
                            !!props.shiftSeriesDayForm.find(
                              (cc) => cc.dayNumber == props.dayNumber
                            )
                              ? false
                              : true
                          }
                          value={seriesTimeForm.shiftEndTime}
                        />
                      </LocalizationProvider>
                    </Box>

                    {index == 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          width: "20%",
                        }}
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            props.handleAddSeriesClick(props.dayNumber)
                          }
                        >
                          <PlusIcon
                            sx={{ color: "#0000008A", cursor: "pointer" }}
                          />
                        </Box>

                        <Box
                          sx={{ cursor: "pointer", paddingTop: "3px" }}
                          onClick={() =>
                            props.handleCopySeriesClick(props.dayNumber)
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17 14.5H11C10.725 14.5 10.5 14.275 10.5 14V6C10.5 5.725 10.725 5.5 11 5.5H15.3781L17.5 7.62188V14C17.5 14.275 17.275 14.5 17 14.5ZM11 16H17C18.1031 16 19 15.1031 19 14V7.62188C19 7.225 18.8406 6.84375 18.5594 6.5625L16.4406 4.44063C16.1594 4.15938 15.7781 4 15.3813 4H11C9.89687 4 9 4.89688 9 6V14C9 15.1031 9.89687 16 11 16ZM7 8C5.89688 8 5 8.89687 5 10V18C5 19.1031 5.89688 20 7 20H13C14.1031 20 15 19.1031 15 18V17H13.5V18C13.5 18.275 13.275 18.5 13 18.5H7C6.725 18.5 6.5 18.275 6.5 18V10C6.5 9.725 6.725 9.5 7 9.5H8V8H7Z"
                              fill="black"
                              fillOpacity="0.54"
                            />
                          </svg>
                        </Box>
                      </Box>
                    )}

                    {index == 1 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          cursor: "pointer",
                          width: "20%",
                        }}
                        onClick={() => {
                          props.handleRemoveSeriesClick(props.dayNumber);
                        }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.3281 5.6125L9.73438 6.5H14.2656L13.6719 5.6125C13.625 5.54375 13.5469 5.5 13.4625 5.5H10.5344C10.45 5.5 10.3719 5.54062 10.325 5.6125H10.3281ZM14.9219 4.78125L16.0688 6.5H16.5H18H18.25C18.6656 6.5 19 6.83437 19 7.25C19 7.66563 18.6656 8 18.25 8H18V17.5C18 18.8813 16.8813 20 15.5 20H8.5C7.11875 20 6 18.8813 6 17.5V8H5.75C5.33437 8 5 7.66563 5 7.25C5 6.83437 5.33437 6.5 5.75 6.5H6H7.5H7.93125L9.07812 4.77812C9.40312 4.29375 9.95 4 10.5344 4H13.4625C14.0469 4 14.5938 4.29375 14.9187 4.77812L14.9219 4.78125ZM7.5 8V17.5C7.5 18.0531 7.94688 18.5 8.5 18.5H15.5C16.0531 18.5 16.5 18.0531 16.5 17.5V8H7.5ZM10 10V16.5C10 16.775 9.775 17 9.5 17C9.225 17 9 16.775 9 16.5V10C9 9.725 9.225 9.5 9.5 9.5C9.775 9.5 10 9.725 10 10ZM12.5 10V16.5C12.5 16.775 12.275 17 12 17C11.725 17 11.5 16.775 11.5 16.5V10C11.5 9.725 11.725 9.5 12 9.5C12.275 9.5 12.5 9.725 12.5 10ZM15 10V16.5C15 16.775 14.775 17 14.5 17C14.225 17 14 16.775 14 16.5V10C14 9.725 14.225 9.5 14.5 9.5C14.775 9.5 15 9.725 15 10Z"
                            fill="#F2460D"
                          />
                        </svg>
                      </Box>
                    )}
                  </Box>
                )
              )}
        </Box>
      </Box>
    </>
  );
};
