import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";

export const Dashboard = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/dashboard");
  }, []);

  return <></>;
};
