import { ReactNode } from "react";
import {
  OutlinedInput,
  Select,
  Stack,
  Chip,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Typography16_400 } from "../typography";
import { grey } from "@mui/material/colors";

interface IMultiSelectDropdown {
  handleChange: (e: any) => void;
  children: ReactNode;
  value: any;
  label: any;
  errorMessage?: string;
  listItems: any[];
  handleRemove: (e: any) => void;
  labelValue?: string;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export const MultiSelectDropdown = (props: IMultiSelectDropdown) => {
  return (
    <>
      <Select
        placeholder={props.placeholder}
        size="small"
        displayEmpty
        IconComponent={ExpandMoreIcon}
        sx={{
          ...props.sx,
          width: "100%",
          background: "#F5FAFE",
          "& .MuiSvgIcon-root": { color: "#0D80F2" },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage
              ? "red !important"
              : "#CFE5FC",
            borderRadius: "8px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: !!props.errorMessage
              ? "red"
              : "#0D80F2",
            borderRadius: "8px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: !!props.errorMessage
              ? "2px solid red !important"
              : "2px solid #0D80F2 !important",
            borderRadius: "8px",
          },
          "& .MuiInputLabel-root": { display: "none" },
           "& .Mui-disabled.MuiSvgIcon-root": { color: "#B6B9BC !important" },
          "& .Mui-disabled": {
            background: "#F1F2F4 !important",
          },
          Input: {
            "&::placeholder": {
              textOverflow: "ellipsis !important",
              color: "red",
              opacity: "5",
            },
          },
        }}
        multiple
        value={props.value}
        onChange={props.handleChange}
        input={<OutlinedInput label={props.label} />}
        renderValue={(selected) => {
          if (!!!selected || selected.length <= 0) {
            return (
              <Typography16_400 text={props.placeholder!} color="#6EB2F7" />
            );
          }

          if (!!selected && selected.length <= 2) {
            return (
              <Stack gap={1} direction="row" flexWrap="wrap">
                {selected?.map((value: any) => (
                  <Chip
                    disabled={!!props.disabled && props.disabled ? true : false}
                    sx={{
                      background: "#E7F2FE",
                      border: "1px solid #CFE6FC",
                      borderRadius: "4px",
                      height: "32px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22.4px",
                    }}
                    key={
                      !!props.listItems && props.listItems.length > 0
                        ? !!props.labelValue
                          ? props.listItems.filter((cc) => cc.id == value)[0][
                              props.labelValue
                            ]
                          : props.listItems.filter((cc) => cc.id == value)[0]
                              .name
                        : ""
                    }
                    label={
                      !!props.listItems && props.listItems.length > 0
                        ? !!props.labelValue
                          ? props.listItems.filter((cc) => cc.id == value)[0][
                              props.labelValue
                            ]
                          : props.listItems.filter((cc) => cc.id == value)[0]
                              .name
                        : ""
                    }
                    onDelete={() => {
                      props.handleRemove(value);
                    }}
                    deleteIcon={
                      <CloseIcon
                        sx={{ color: "#0D80F2 !important" }}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
              </Stack>
            );
          }

          if (!!selected && selected.length > 2) {
            return (
              <Stack gap={1} direction="row" flexWrap="wrap">
                {selected.slice(0, 2).map((value: any) => (
                  <Chip
                    disabled={!!props.disabled && props.disabled ? true : false}
                    sx={{
                      background: "#E7F2FE",
                      border: "1px solid #CFE6FC",
                      borderRadius: "4px",
                      height: "32px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "22.4px",
                    }}
                    key={
                      !!props.listItems && props.listItems.length > 0
                        ? !!props.labelValue
                          ? props.listItems.filter((cc) => cc.id == value)[0][
                              props.labelValue
                            ]
                          : props.listItems.filter((cc) => cc.id == value)[0]
                              .name
                        : ""
                    }
                    label={
                      !!props.listItems && props.listItems.length > 0
                        ? !!props.labelValue
                          ? props.listItems.filter((cc) => cc.id == value)[0][
                              props.labelValue
                            ]
                          : props.listItems.filter((cc) => cc.id == value)[0]
                              .name
                        : ""
                    }
                    onDelete={() => {
                      props.handleRemove(value);
                    }}
                    deleteIcon={
                      <CloseIcon
                        sx={{ color: "#0D80F2 !important" }}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}

                <Chip
                  disabled={!!props.disabled && props.disabled ? true : false}
                  sx={{
                    background: "#E7F2FE",
                    border: "1px solid #CFE6FC",
                    borderRadius: "4px",
                    height: "32px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  label={`+${selected.length - 2}`}
                />
              </Stack>
            );
          }
        }}
        disabled={!!props.disabled ? props.disabled : false}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
              overflowY: "scroll",
              scrollbarWidth: "thin",
              scrollbarColor: grey["400"],
            },
          },
        }}
      >
        {props.children}
      </Select>
      {!!props.errorMessage && (
        <span style={{ fontSize: "10px", fontWeight: "400", color: "red" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22.4px",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        </span>
      )}
    </>
  );
};
