import { Box, FormControlLabel, Typography } from "@mui/material";
import { ShareShiftCheckBox } from "../../checkbox/shareShiftCheckbox";
import { useShareOpenShiftsContext } from "../../../../libs/context-lib/shiftDetailContext";

interface ICalendarShareShiftDetail {
  shiftType: any;
  range: any;
  qualification: any;
  id: string;
}

export const CalendarShareShiftDetail = (props: ICalendarShareShiftDetail) => {
  const { shareOpenShifts, setShareOpenShifts } = useShareOpenShiftsContext();

  const handleSelectedShift = (event: any) => {
    let selectedShiftList = shareOpenShifts.shift_ids.slice(0);

    if (event.target.checked) {
      selectedShiftList.push(event.target.value);
    } else {
      selectedShiftList = selectedShiftList.filter(
        (cc) => cc != event.target.value
      );
    }

    setShareOpenShifts({
      ...shareOpenShifts,
      shift_ids: selectedShiftList,
    });
  };

  const checkCheckBoxStatus = () => {
    let checked = false;

    if (!!shareOpenShifts.shift_ids && shareOpenShifts.shift_ids.length > 0) {
      if (
        shareOpenShifts.shift_ids.find(
          (cc: string) => cc == props.id
        )
      ) {
        checked = true;
      }
      else {
        checked = false;
      }
    }

    return checked;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          borderBottom: "1px solid #E3E5E8",
        }}
      >
        <Box
          sx={{
            width: "33%",
          }}
        >
          <FormControlLabel
            sx={{
              alignContent: "center",
              gap: "8px",
              paddingLeft: "15px",
              color: "#000000DE",
              "& .MuiTypography-root": {
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "24px",
              },
            }}
            key={props.id}
            control={
              <ShareShiftCheckBox
                value={props.id}
                onChange={handleSelectedShift}
                checked={checkCheckBoxStatus()}
              />
            }
            label={props.shiftType}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "33%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000DE",
              fontSize: "14px",
              fontWeight: "400",
              letterSpacing: "0.17px",
            }}
          >
            {props.range}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "33%",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000000DE",
              paddingLeft: "5px",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              letterSpacing: "0.17px",
            }}
          >
            {props.qualification}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
