import {
    TextField,
    Typography,
  } from "@mui/material";
  import { ChangeEvent } from "react";
 
  interface ISignUpTextField {
    value: string;
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errorMessage?: string;
  placeholder: string;
  }
 
  export const SignUpTextField = (props: ISignUpTextField) => {
    return (
      <>
        <TextField
          size="small"
          fullWidth
          sx={{
            minHeight: "40px",
            width: "100%",
            borderRadius: 8,
            border: !!props.errorMessage ? "#F2460D" : "#F0F0F0",

            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: !!props.errorMessage ? "#F2460D" : "#F0F0F0",
              },
              "&:hover fieldset": {
                borderColor: !!props.errorMessage ? "#F2460D" : "#F0F0F0",
              },
              "&.Mui-focused fieldset": {
                borderColor: !!props.errorMessage ? "#F2460D" : "#F0F0F0",
              },
            },
            input: {
              "&::placeholder": {
                textOverflow: "ellipsis !important",
                color: "#00000099",
                opacity: "5",
              },
            },
          }}
          inputProps={{
            style: {
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              color: "#0000000F",
              letterSpacing: "0.15px",
              backgroundColor: "#F0F0F0",
              borderRadius: "8px",
            },
          }}
          InputProps={{
            style: {
              backgroundColor: "#F0F0F0",
              borderRadius: "8px",
            },
          }}
          placeholder={props.placeholder}
          variant="outlined"
          value={props.value}
          onChange={props.handleChange}
        />

        {!!props.errorMessage && (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "20px",
              color: "#F2460D",
              backgroundColor: "rgba(240,240,240,0) !important",
              alignItems: "flex-start",
            }}
            className="red-text"
          >
            {props.errorMessage}
          </Typography>
        )}
      </>
    );
  };