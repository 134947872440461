import { useEffect, useState } from "react";
import { DetailSettingBox } from "../../organism";
import { useNavigate, useParams } from "react-router-dom";
import {
  Decryption,
  EmptyGuid,
  emptyRoleDetail,
} from "../../../libs/common-lib";
import {
  loadingIconState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { OrganizationRole } from "../../../libs/model-lib";
import {
  createRole,
  getOrganizationRoleById,
  updateRole,
} from "../../../libs/services-lib";
import Joi from "joi";
import { RoleDetailContext } from "../../../libs/context-lib/roleDetailContext";
import { RolesDetailFoem } from "../../organism/roles/rolesDetailFoem";

const RoleDetailTemplate = () => {
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [roleDetail, setRoleDetail] =
    useState<OrganizationRole>(emptyRoleDetail);

  useEffect(() => {
    (async function () {
      if (!!id) {
        if (Decryption(id) === EmptyGuid || !!!Decryption(id)) {
          setRoleDetail(emptyRoleDetail);
        } else {
          loadingIconState.setState(true);
          let rDetail = await getOrganizationRoleById(Decryption(id));
          setRoleDetail(rDetail);
          loadingIconState.setState(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!!errorMessage && errorMessage.length > 0) {
      let selectedPara = document.querySelectorAll("p.red-text");
      if (!!selectedPara && selectedPara.length > 0 && !!selectedPara[0]) {
        selectedPara[0].closest("div")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [errorMessage]);

  const schema = Joi.object<OrganizationRole>({
    id: Joi.number().allow(0),
    role_name: Joi.string().required().max(255).messages({
      "string.empty": `Role name cannot be an empty field`,
      "string.max": `The maximum character limit should be 255`,
    }),
    org_id: Joi.string().allow(""),
    role_type: Joi.number().allow(0),
    abbreviation: Joi.string().max(5).required().messages({
      "string.max": `The maximum character limit should be 5`,
      "string.empty": `Abbreviation cannot be an empty field`,
    }),
    qualifications: Joi.array().min(1).required().messages({
      "array.min": `Select primary qualification`,
    }),
    is_active: Joi.boolean(),
    location_id: Joi.array().allow(null),
    department_id: Joi.array().allow(null),
    sub_department_id: Joi.array().allow(null),
  }).options({ abortEarly: false });

  const handleRolesSaveClick = async () => {
    const result = schema.validate(roleDetail);
    let errorMessage: any[] = [];

    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    let rDetail = { ...roleDetail };

    if (!!rDetail.id && rDetail.id !== 0) {
      rDetail.org_id = organizationDetailState.getState().id;
      rDetail.role_type = 85;
      try {
        await updateRole(rDetail);
      } catch (error) {}
    } else {
      rDetail.id = 0;
      rDetail.org_id = organizationDetailState.getState().id;
      rDetail.role_type = 85;
      try {
        await createRole(rDetail);
        setRoleDetail(emptyRoleDetail);
      } catch (error) {}
    }
    loadingIconState.setState(false);
  };

  const handleCancelClick = () => {
    navigate("/settings/roles");
  };

  return (
    <DetailSettingBox
      navigationSelectedMenu="Roles"
      playBoxTitle="Setting up roles"
      playBoxDuration="1 min."
      pageTitle={
        !!id && (Decryption(id) === EmptyGuid || !!!Decryption(id)) ? "Add role" : "Update role"
      }
      handleSaveClick={handleRolesSaveClick}
      handleCancelClick={handleCancelClick}
    >
      <RoleDetailContext.Provider value={{ roleDetail, setRoleDetail }}>
        <RolesDetailFoem errorMessage={errorMessage} />
      </RoleDetailContext.Provider>
    </DetailSettingBox>
  );
};

export default RoleDetailTemplate;
