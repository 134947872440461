import { useEffect, useLayoutEffect, useState } from "react";
import { UnitDetailList } from "../../../libs/model-lib";
import {
  activeConfirmationPopupState,
  confirmationPopupTitleState,
  inActiveConfirmationPopupState,
  unitListState,
} from "../../../libs/state-management-lib";
import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemButton,
  Menu,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  ActiveConfirmationPopup,
  ActiveIcon,
  ContainedButton,
  ContainedDeleteButton,
  InActiveConfirmationPopup,
  OutlinedButton,
  Typography14_400,
  Typography16_400,
  Typography16_600,
  Typography32_600,
  UnitIcon,
} from "../../atom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IosSwitch } from "../../atom/Switch/iosSwitch";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

interface IUnitDashboard {
  handleEditUnitCallBack: (unitId: string) => void;
  handleActiveUnit: (activeUnitId: string) => void;
  handleInActiveUnit: (inActiveUnitId: string) => void;
  handleAddUnit: () => void;
}
export const UnitDashboard = (props: IUnitDashboard) => {
  const [unitList, setunitList] = useState<UnitDetailList[]>(
    unitListState.getState().unitDetailList
  );
  const [rowData, setRowData] = useState<any>([]);
  const [unitActionState, setUnitActionState] = useState<{
    anchorEl: HTMLElement | null;
    rowId: number | null;
  }>({ anchorEl: null, rowId: null });
  const [activeChecked, setActiveChecked] = useState<boolean>(false);
  const [activeUnitId, setActiveUnitId] = useState("");
  const [showActiveConfirmationPopup, setshowActiveConfirmationPopup] =
    useState(false);
  const [showInActiveConfirmationPopup, setshowInActiveConfirmationPopup] =
    useState(false);

  useLayoutEffect(() => {
    const unitListStateSub = unitListState.subscribe(async (state) => {
      setunitList(state.unitDetailList);
    });

    const inActiveConfirmationPopupStateSub =
      inActiveConfirmationPopupState.subscribe(async (state) => {
        setshowInActiveConfirmationPopup(state);
      });

    const activeConfirmationPopupStateSub =
      activeConfirmationPopupState.subscribe(async (state) => {
        setshowActiveConfirmationPopup(state);
      });

    return () => {
      unitListStateSub();
      inActiveConfirmationPopupStateSub();
      activeConfirmationPopupStateSub();
    };
  }, []);

  useEffect(() => {
    refreshEvent();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>, params: any) => {
    setUnitActionState({ anchorEl: event.currentTarget, rowId: params.id });
    setActiveChecked(params.row.status);
  };
  const handleClose = () => {
    setUnitActionState({ anchorEl: null, rowId: null });
  };
  const handleEditUnitClick = async (unitId: any) => {
    props.handleEditUnitCallBack(unitId.id);
  };
  const handleActiveClick = (params: any) => {
    if (activeChecked) {
      activeConfirmationPopupState.setState(true);
    } else {
      inActiveConfirmationPopupState.setState(true);
    }
    let unitId = params.id.toString();
    setActiveUnitId(unitId);
    let unitName = params.row.name.toString();
    confirmationPopupTitleState.setState(unitName);
    setUnitActionState({ anchorEl: null, rowId: null });
  };

  const handleCloseClick = () => {
    activeConfirmationPopupState.setState(false);
  };

  const handleInactivePopupClick = () => {
    inActiveConfirmationPopupState.setState(false);
  };

  const handleActiveUnit = () => {
    props.handleActiveUnit(activeUnitId);
  };

  const handleInActiveUnit = () => {
    props.handleInActiveUnit(activeUnitId);
  };

  const refreshEvent = () => {
    const modifiedData = unitList.map((cc) => ({
      id: cc.id,
      name: cc.subdepartment_name,
      status: cc.is_active,
    }));
    setRowData(modifiedData);
  };

  const rows = unitList.map((cc) => ({
    id: cc.id,
    name: cc.subdepartment_name,
    status: cc.is_active,
  }));

  const handleAddUnitClick = () => {
    props.handleAddUnit();
  };

  return (
    <>
      {unitList.length <= 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "24px",
            width: "100%",
            marginTop: "6%",
          }}
        >
          <SearchIcon fontSize="large" sx={{ color: "rgba(61, 153, 245, 0.5)" }}/>
          <Typography32_600 text="No active sub-department"  color="rgba(61, 153, 245, 0.5)" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #0D80F2",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  cursor: "pointer",
                }}
                onClick={handleAddUnitClick}
              >
                <AddIcon fontSize="medium" sx={{ color: "#0D80F2" }} />
                <Typography16_600 text="Create from scratch" color="#0D80F2" />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Create sub-department from scratch, providing"
                  color="#8F98A3"
                />
                <Typography16_400 text="mandatory details." color="#8F98A3" />
              </Box>
            </Box>
            <Divider
              sx={{
                borderColor: "#CFE5FC",
              }}
              orientation="vertical"
              flexItem
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: "8px",
                  border: "1px solid #ABB2BA",
                  borderRadius: "8px",
                  height: "124px",
                  width: "370px",
                  backgroundColor:"#F1F2F4"
                }}
              >
                <AddIcon fontSize="medium" sx={{ color: "#8F98A3" }} />
                <Typography16_600 text="Start with template" color="#8F98A3" />
              </Box>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginLeft: "5%",
                }}
              >
                <Typography16_400
                  text="Create sub-department using a template with"
                  color="#8F98A3"
                />
                <Typography16_400
                  text="placeholder details and edit the letter as needed."
                  color="#8F98A3"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {unitList.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "6px",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",

                borderRadius: "8px",
                width: "100%",
                height: "90%",                
              }}
            >
              <DataGrid
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .theme--header": {
                    backgroundColor: "#F9FAFA",
                  },
                  "& .MuiDataGrid-row": {
                    borderBottom: "1px solid #E3E5E8",
                  },
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F5FAFE",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    background: "#F9FAFA",
                  },
                  "& .MuiDataGrid-columnHeaderTitle" : {
                    fontWeight:"600",
                    color:"rgba(115, 127, 140, 1)"
                  },
                  "& .MuiCheckbox-root": {
                    color: "#ABB2BA !important",
                  },
                  "& .Mui-checked": {
                    color: "#0D80F2 !important",
                  },
                  "& .MuiDataGrid-scrollbarFiller": {
                    backgroundColor: "#F9FAFA",
                  },
                  border: "1px solid #CFE5FC",
                }}
                key={rowData.id}
                onRowClick={handleEditUnitClick}
                rows={rows}
                columns={[
                  {
                    field: "icon",
                    headerName: "Sub-department",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: true,
                    renderCell: (params: any) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "8px 4px",
                            gap: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid #E3E5E8",
                              borderRadius: "8px",
                              height: "32px",
                              width: "32px",
                            }}
                          >
                            <UnitIcon
                              sx={{
                                color: "#323232",
                                left: "1.2px",
                                top: "3.13px",
                              }}
                            />
                          </Box>
                          <Typography14_400
                            text={params.row.name}
                            color="#0D80F2"
                          />
                        </Box>
                      );
                    },
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    headerClassName: "theme--header",
                    flex: 1,
                    sortable: false,
                    renderCell: (params: any) => {
                      return (
                        <Chip
                          label={params.row.status ? "Active" : "Inactive"}
                          sx={{
                            height: "22px",
                            background: params.row.status
                              ? "#F7FDF8"
                              : "#ffece5",
                            border: "1px solid #EBFAEE",
                            borderRadius: "4px",
                            color: params.row.status ? "#29A347" : "#ff5722",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        />
                      );
                    },
                  },
                  {
                    field: "actions",
                    type: "actions",
                    headerClassName: "theme--header",
                    flex: 1,
                    cellClassName: "actions",
                    align: "right",
                    renderCell: (params: any) => {
                      const isOpen = unitActionState.rowId === params.id;
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                          key={`action-${params.id}`}
                        >
                          <Box
                            sx={{ color: "#0D80F2" }}
                            onClick={(event) => handleClick(event, params)}
                          >
                            <IconButton
                              sx={{
                                background: "#FFFFFF",
                                color: "#0D80F2",
                                borderRadius: "10px !important",
                              }}
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </Box>
                          <Menu
                            anchorEl={unitActionState.anchorEl}
                            id="account-menu"
                            open={isOpen}
                            onClose={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                width: "228px",
                                borderRadius: "8px",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&::before": {
                                  content: '""',
                                  display: "bstaffk",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-1-${params.id}`}
                              onClick={() => {}}
                            >
                              <DeleteIcon sx={{ color: "#0D80F2" }} />{" "}
                              <Typography16_400 text="Delete" color="#031A30" />
                            </ListItemButton> */}
                            <ListItemButton
                              sx={{ gap: "7px" }}
                              key={`menu-item-2-${params.id}`}
                              onClick={() => handleActiveClick(params)}
                            >
                              <ActiveIcon sx={{ color: "#0D80F2" }} />
                              <Typography16_400 text="Active" color="#031A30" />
                              <Box sx={{ marginLeft: "35%" }}>
                                <IosSwitch checked={activeChecked} />
                              </Box>
                            </ListItemButton>
                          </Menu>
                        </Box>
                      );
                    },
                  },
                ]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableRowSelectionOnClick
                disableColumnMenu
              />
            </Box>
          </Box>
          <ActiveConfirmationPopup
            title={`Deactivate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to deactivate “${confirmationPopupTitleState.getState()}” sub-department?`}
            open={showActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleCloseClick}
              />
              <ContainedDeleteButton
                sx={{ height: "40px" }}
                text="Deactivate sub-department"
                handleClick={handleActiveUnit}
              />
            </Box>
          </ActiveConfirmationPopup>
          <InActiveConfirmationPopup
            title={`Activate ${confirmationPopupTitleState.getState()}`}
            content={`Are you sure you want to activate “${confirmationPopupTitleState.getState()}” sub-department?`}
            open={showInActiveConfirmationPopup}
            handleClick={handleCloseClick}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
                gap: "16px",
              }}
            >
              <OutlinedButton
                sx={{ height: "40px" }}
                text="Cancel"
                handleClick={handleInactivePopupClick}
              />
              <ContainedButton
                sx={{ height: "40px" }}
                text="Activate sub-department"
                handleClick={handleInActiveUnit}
              />
            </Box>
          </InActiveConfirmationPopup>
        </>
      )}
    </>
  );
};
