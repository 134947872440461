import {
  Box,
  Chip,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import {
  CalendarTodayOutlinedIcon,
  LableTextFieldBox,
  PlatformTextField,
  Typography12_600,
} from "../../atom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import { Dayjs } from "dayjs";
import { TextFieldLableTypography } from "../../atom/typography/textFieldLableTypography";
import { CalendarDropDown } from "../../atom/dropdown/calendarDropdown";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { grey } from "@mui/material/colors";
import {
  DesktopTimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers-pro";
import { CalendarRotationShiftDaySequence } from "./calendarRotationShiftDaySequence";
import { getErrorDetail } from "../../../libs/common-lib";
import { format } from "date-fns";
import {
  useShiftRotationContext,
} from "../../../libs/context-lib/shiftDetailContext";

interface ICalendarRotationShiftDateForm {
  errorMessage: any[];
}

export const CalendarRotationShiftDateForm = (
  props: ICalendarRotationShiftDateForm
) => {
  const [shiftStartTime, setShiftStartTime] = useState<Dayjs | null>(null);
  const [shiftEndTime, setShiftEndTime] = useState<Dayjs | null>(null);
  const [shiftStartTimeError, setShiftStartTimeError] =
    useState<TimeValidationError | null>(null);
  const [shiftEndTimeError, setShiftEndTimeError] =
    useState<TimeValidationError | null>(null);
  const { shiftRotation, setShiftRotation } = useShiftRotationContext();

  const handleDateInterval = (newValue: any) => {
    if (!!newValue && newValue.length > 1 && !!newValue[1]) {
      setShiftRotation({
        ...shiftRotation,
        rotation_start_date: format(newValue[0].toDate(), "MM-dd-yyyy"),
        rotation_end_date: format(newValue[1].toDate(), "MM-dd-yyyy"),
      });
    }
  };

  const getRotationName = () => {
    if (
      shiftRotation.rotation_name == "0 off" ||
      shiftRotation.rotation_name == "0 on"
    ) {
      return "";
    } else {
      return shiftRotation.rotation_name;
    }
  };

  const handleStarttimeClear = () => {
    setShiftStartTime(null);
    setShiftEndTime(null);
    shiftRotation.shift_start_time = "";
    
  };

  const handleEndtimeClearclick = () => {
    setShiftEndTime(null);
    shiftRotation.shift_end_time = "";
  };

  return (
    <>
      <Box
        sx={{
          width: "calc(100%-32px)",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          border: "1px solid #F1F2F4",
          padding: "16px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "98%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#17191C",
              lineHeight: "24.3px",
            }}
          >
            Date and duration
          </Typography>
          <Chip
            sx={{
              backgroundColor: "#F9FAFA",
              border: "1px solid #F1F2F4",
              color: "#8F98A3",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "22.4px",
              borderRadius: "10px !important",
            }}
            label="0h per week"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#737F8C",
              justifyContent: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography
                text="Dates interval"
                required={true}
                sx={{
                  fontWeight: "600",
                  color: "#737F8C",
                  marginBottom: "-7px",
                }}
              />
            </LableTextFieldBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["SingleInputDateRangeField"]}>
                <DateRangePicker
                  sx={{
                    justifyContent: "center",
                    background: "#F5FAFE",
                    width: "100%",
                    "& .MuiSvgIcon-root": {
                      color: "#0D80F2 !important",
                    },
                    ".MuiInputBase-input": {
                      padding: "8.5px 14px !important",
                      fontSize: "16px",
                      fontWeight: "400",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                        !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationDateInterval", props.errorMessage)
                            ? "red"
                            : "#CFE5FC !important",
                      },
                      "&:hover fieldset": {
                        borderColor:
                        !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationDateInterval", props.errorMessage)
                            ? "red"
                            : "#0D80F2 !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                        !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationDateInterval", props.errorMessage)
                            ? "red"
                            : "#0D80F2 !important",
                      },
                      borderRadius: "8px",
                    },
                    Input: {
                      "&::placeholder": {
                        color: "#6EB2F7",
                        opacity: "5",
                      },
                    },
                  }}
                  slotProps={{
                    textField: {
                      inputProps: {
                        readOnly: true,
                      },
                      InputProps: {
                        endAdornment: (
                          <CalendarTodayOutlinedIcon
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      },
                      placeholder: "Date interval",
                    },
                  }}
                  slots={{ field: SingleInputDateRangeField }}
                  name="allowedRange"
                  format="MMM DD, YYYY"
                  onChange={(newValue) => {
                    handleDateInterval(newValue);
                  }}
                  disablePast={true}
                />
              </DemoContainer>
            </LocalizationProvider>
            <span
              style={{
                fontSize: "10px",
                fontWeight: "400",
                color: "red",
                height: "22.4px",
              }}
            >
              {!!props.errorMessage && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("rotationDateInterval", props.errorMessage)}
                </Typography>
              )}
            </span>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LableTextFieldBox>
              <TextFieldLableTypography
                text="Rotation presets"
                required={false}
              />
            </LableTextFieldBox>
            <CalendarDropDown
              disabled
              sx={{
                ".MuiInputBase-input": {
                  color: "#6EB2F7  !important",
                },

                "&.Mui-disabled": {
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #ABB2BA !important",
                  },
                },
              }}
              handleChange={function (e: any): void {}}
              value={1}
            >
              <MenuItem value={1}> Select the preset </MenuItem>
            </CalendarDropDown>
            <span style={{ height: "22.4px" }}></span>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              color: "#737F8C",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Shift start time"
                  required={true}
                />
              </LableTextFieldBox>
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                    fill="#737F8C"
                  />
                </svg>
              </Box>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{
                  justifyContent: "center",
                  background: "#F5FAFE",
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: "#0D80F2 !important",
                  },
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationStartTime", props.errorMessage)
                          ? "red"
                          : "#CFE5FC !important",
                    },
                    "&:hover fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationStartTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationStartTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    borderRadius: "8px",
                  },
                  Input: {
                    "&::placeholder": {
                      color: "#6EB2F7",
                      opacity: "5",
                    },
                  },
                }}
                onChange={(newValue: any) => {
                  setShiftStartTime(newValue);
                  setShiftEndTime(null);
                  setShiftRotation({
                    ...shiftRotation,
                    shift_start_time: format(newValue.toDate(), "HH:mm"),
                  });
                }}
                onError={(newError) => setShiftStartTimeError(newError)}
                minutesStep={5}
                value={shiftStartTime}
                slotProps={{
                  field: { 
                    clearable: true,
                    onChange: handleStarttimeClear,                    
                   },
                  textField: {
                    placeholder: "Shift start time",
                    inputProps: {
                      readOnly: true,
                    },
                  },
                  layout: {
                    sx: {
                      ul: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                          {
                            borderRadius: "32px",
                            backgroundColor: grey["400"],
                          },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                          {
                            backgroundColor: "#0000000",
                          },
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "red",
                height: "22.4px",
              }}
            >
              {!!props.errorMessage && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("rotationStartTime", props.errorMessage)}
                </Typography>
              )}
            </span>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography
                  text="Shift end time "
                  required={true}
                />
              </LableTextFieldBox>
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                    fill="#737F8C"
                  />
                </svg>
              </Box>
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{
                  justifyContent: "center",
                  background: "#F5FAFE",
                  width: "100%",
                  "& .MuiSvgIcon-root": {
                    color: "#0D80F2 !important",
                  },
                  ".MuiInputBase-input": {
                    padding: "8.5px 14px !important",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationEndTime", props.errorMessage)
                          ? "red"
                          : "#CFE5FC !important",
                    },
                    "&:hover fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationEndTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                      !!props.errorMessage && props.errorMessage.length > 0 && !!getErrorDetail("rotationEndTime", props.errorMessage)
                          ? "red"
                          : "#0D80F2 !important",
                    },
                    borderRadius: "8px",
                  },
                  Input: {
                    "&::placeholder": {
                      color: "#6EB2F7",
                      opacity: "5",
                    },
                  },
                }}
                minutesStep={5}
                value={shiftEndTime}
                onError={(newError) => setShiftEndTimeError(newError)}
                slotProps={{
                  field: {
                    clearable: true,
                    onChange: handleEndtimeClearclick,
                  },
                  textField: {
                    placeholder: "Shift end time",
                    inputProps: {
                      readOnly: true,
                    },
                  },
                  layout: {
                    sx: {
                      ul: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb":
                          {
                            borderRadius: "32px",
                            backgroundColor: grey["400"],
                          },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                          {
                            backgroundColor: grey["500"],
                          },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                          {
                            backgroundColor: "#0000000",
                          },
                      },
                    },
                  },
                }}
                onChange={(newValue: any) => {
                  setShiftEndTime(newValue);
                  setShiftRotation({
                    ...shiftRotation,
                    shift_end_time: format(newValue.toDate(), "HH:mm"),
                  });
                }}
              />
            </LocalizationProvider>
            <span
              style={{
                fontSize: "10px",
                fontWeight: "400",
                color: "red",
                height: "22.4px",
              }}
            >
              {!!props.errorMessage && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22.4px",
                  }}
                  className="red-text"
                >
                  {getErrorDetail("rotationEndTime", props.errorMessage)}
                </Typography>
              )}
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            color: "#737F8C",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#17191C",
                lineHeight: "22.4px",
              }}
            >
              Rotation sequence
            </Typography>
          </Box>
          <CalendarRotationShiftDaySequence />
        </Box>
        <Box
          sx={{
            width: "100%",
            color: "#737F8C",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              <LableTextFieldBox>
                <TextFieldLableTypography text="Save preset" required={false} />
              </LableTextFieldBox>
              <Box sx={{ cursor: "pointer" }}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                    fill="#737F8C"
                  />
                </svg>
              </Box>
            </Box>

            <FormControlLabel
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: "16px !important",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#000000DE",
                },
              }}
              control={
                <Switch
                  disabled
                  sx={{
                    width: "58px",
                    ".Mui-checked": {
                      color: "#0D80F2 !important",
                    },
                    ".Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "#0D80F2 !important",
                    },
                  }}
                  checked={false}
                />
              }
              label="Save as preset"
            />
          </Box>
          <Box
            sx={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <LableTextFieldBox>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <Typography12_600 text="Rotation name" color="#737F8C" />
                <Box sx={{ cursor: "pointer" }}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.9999 2.60039C5.01727 2.60039 2.5999 5.01776 2.5999 8.00039C2.5999 10.983 5.01727 13.4004 7.9999 13.4004C10.9825 13.4004 13.3999 10.983 13.3999 8.00039C13.3999 5.01776 10.9825 2.60039 7.9999 2.60039ZM1.3999 8.00039C1.3999 4.35502 4.35453 1.40039 7.9999 1.40039C11.6453 1.40039 14.5999 4.35502 14.5999 8.00039C14.5999 11.6458 11.6453 14.6004 7.9999 14.6004C4.35453 14.6004 1.3999 11.6458 1.3999 8.00039ZM7.06658 5.8359C7.064 5.3173 7.48477 4.90039 7.99857 4.90039C8.51372 4.90039 8.93324 5.31679 8.93324 5.83372C8.93324 6.34909 8.51528 6.76706 7.99991 6.76706C7.48526 6.76706 7.06775 6.35027 7.06658 5.8359ZM7.06658 5.8359C7.06657 5.83517 7.06657 5.83445 7.06657 5.83372H7.66656L7.06659 5.83807C7.06658 5.83735 7.06658 5.83662 7.06658 5.8359ZM8.26656 5.83372C8.26656 5.83302 8.26656 5.83231 8.26656 5.8316C8.26656 5.831 8.26655 5.8304 8.26655 5.82979M7.9999 7.40039C8.33127 7.40039 8.5999 7.66902 8.5999 8.00039V11.3337C8.5999 11.6651 8.33127 11.9337 7.9999 11.9337C7.66853 11.9337 7.3999 11.6651 7.3999 11.3337V8.00039C7.3999 7.66902 7.66853 7.40039 7.9999 7.40039Z"
                      fill="#737F8C"
                    />
                  </svg>
                </Box>
              </Box>

              <PlatformTextField
                placeholder={"Rotation name"}
                value={getRotationName()}
                handleChange={(e: any) => {
                  setShiftRotation({
                    ...shiftRotation,
                    rotation_name: e.target.value,
                  });
                }}
              />
            </LableTextFieldBox>
          </Box>
        </Box>
        {/* <Box
          sx={{
            width: "calc(100%-32px)",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "5px 0px",
          }}
        >
          <CalendarRotationSchedule />
        </Box> */}
      </Box>
    </>
  );
};
