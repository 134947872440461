import { Box } from "@mui/material";
import { OrganisationSetting } from "../organism";
import { SettingNavigation } from "../molecule";

export const SettingsTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SettingNavigation selectedMenu="Organization" />
      <Box
        sx={{
          width: "80%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#ffffff",
          borderRight: "1px solid #CFE6FC",
        }}
      >
        <OrganisationSetting />
      </Box>
    </Box>
  );
};
