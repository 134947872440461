import { axiosInstance, emptyStaffDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import { SearchStaffCriteria, StaffDetail } from "../model-lib";
import {
  organizationDetailState,
} from "../state-management-lib";
import {
  staffDetailListState,
  staffDetailState,
} from "../state-management-lib/staffState";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/Staff`;

export const getStaffDetailList = async () => {
  let pageSize = {
    pageSize: 200,
    pageNumber: 1,
    org_id: organizationDetailState.getState().id,
  };
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/GetStaffList`,
      pageSize
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      staffDetailListState.setState({
        staffDetailList: response.data.data.results,
      });
    }
  } catch (error) {}
};

export const createStaff = async (staffnDetail: StaffDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateStaff`,
      staffnDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getStaffDetailList();
      handleApiSuccessAlert("Talent created successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const updateStaff = async (staffnDetail: StaffDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/UpdateStaff`,
      staffnDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getStaffDetailList();
      handleApiSuccessAlert("Talent updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const getStaffById = async (staffId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetStaffById?staffId=${staffId}&orgId=${
        organizationDetailState.getState().id
      }`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      let staffDetail = response.data.data;

      if (!!staffDetail.agencyDetails) {
        staffDetail.agency_id = staffDetail.agencyDetails.id;
      }

      staffDetailState.setState(staffDetail);

      return staffDetail;
    }

    return emptyStaffDetail;
  } catch (error) {
  }
};

export const getStaffByOrgIdLocId = async (orgId: string, locId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetStaffByOrgAndLocId?orgId=${orgId}&&locId=${locId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      staffDetailListState.setState({
        staffDetailList: response.data.data,
      });

      return response.data.data;
    }

    return [];
  } catch (error) {
  }
};

export const getStaffListBySearchCriteria = async (
  searchStaffCriteria: SearchStaffCriteria
) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/GetStaffList`,
      searchStaffCriteria
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      staffDetailListState.setState({
        staffDetailList: response.data.data,
      });

      return response.data.data;
    }

    return [];
  } catch (error) {
  }
};
