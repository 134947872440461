import {
  Avatar,
  Box,
  ListItemButton,
  Menu,
} from "@mui/material";
import { DashBoardIconBoxWithoutHover, LogoBox } from "../molecule";
import { DashBoardIconBox } from "../molecule";
import {
  DashboardIcon,
  MarketPlaceIcon,
  SchedulingIcon,
  SettingIcon,
  TalentIcon,
  Typography10_400,
  Typography14_600,
  Typography16_400,
} from "../atom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { accessTokenState } from "../../libs/state-management-lib";

export const NavigationBar = () => {
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [menuState, setMenuState] = useState<{
    anchorEl: HTMLElement | null;
  }>({ anchorEl: null });
  const open = profileMenu;
  const navigate = useNavigate();

  const iconStyle = {
    width: "24px",
    height: "24px",
    color: "primary.contrastText",
    "&:hover": {
      color: "#ffffff",
    },
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null });
    setProfileMenu(false);
  };

  const handleLogoutClick = () => {
    accessTokenState.setState({ token: "" });
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "70px",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <LogoBox />
          <Box sx={{ display: "flex", flexDirection: "column", gap:"4px" }}>
            <DashBoardIconBox navigationItem="/dashboard">
              <DashboardIcon sx={iconStyle} />
              <Typography14_600 text="Dashboard" />
            </DashBoardIconBox>
            <DashBoardIconBox navigationItem="/schedule">
              <SchedulingIcon sx={iconStyle} />
              <Typography14_600 text="Schedule" />
            </DashBoardIconBox>
            {/* <DashBoardIconBox navigationItem="/attendance">
              <AttendanceIcon sx={iconStyle} />
              <Typography14_600 text="Attendance" />
            </DashBoardIconBox> */}
            <DashBoardIconBox navigationItem="/talents">
              <TalentIcon sx={iconStyle} />
              <Typography14_600 text="Talents" />
            </DashBoardIconBox>
            <DashBoardIconBox navigationItem="/settings/organization">
              <SettingIcon sx={iconStyle} />
              <Typography14_600 text="Settings" />
            </DashBoardIconBox>
            <DashBoardIconBox navigationItem="/marketplace">
              <MarketPlaceIcon sx={iconStyle} />
              <Typography14_600 text="Marketplace" />
            </DashBoardIconBox>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <DashBoardIconBox navigationItem="">
            <MessageIcon sx={iconStyle} />
            <Typography14_600 text="Messages" />
          </DashBoardIconBox>
          <DashBoardIconBox navigationItem="">
            <NotificationIcon sx={iconStyle} />
            <Typography14_600 text="Notifications" />
          </DashBoardIconBox> */}
          <DashBoardIconBoxWithoutHover navigationItem="/profilesettings/profile-details">
            <Avatar
              variant="square"
              sx={{ cursor: "pointer" }}
            >
              A
            </Avatar>
          </DashBoardIconBoxWithoutHover>
          <DashBoardIconBoxWithoutHover navigationItem="/dashboard" >
            <Typography10_400 text="Powered by" color="#737F8C" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Typography10_400 text="Shiftnex.ai" color="#737F8C" />
              <img
                src="/images/common/logo.svg"
                style={{ width: "16px", height: "16px" }}
              />
              <Typography10_400 text="M.1" color="#737F8C" />
            </Box>
          </DashBoardIconBoxWithoutHover>
        </Box>
        {showProfileMenu && (
          <Menu
            anchorEl={menuState.anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                width: "320px",
                boxShadow:
                  "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: 170,
            }}
            anchorOrigin={{
              horizontal: 430,
              vertical: "bottom",
            }}
          >
            <ListItemButton onClick={handleLogoutClick}>
              <LogoutIcon
                fontSize="medium"
                sx={{ color: "#00000099", width: "50px" }}
              />
              <Typography16_400 text="Log out" color="#000000DE" />
            </ListItemButton>
          </Menu>
        )}
      </Box>
    </>
  );
};
