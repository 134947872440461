import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { GenericMasterData, TimeZoneDetail } from "../model-lib";
import { JobQualification } from "../model-lib/jobQualification";
import { ApiAlert } from "../model-lib/apiAlert";

export const loadingIconState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "loadingIconState" }))
);

export const successAlertState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "successAlertState" }))
);

export const updateAlertState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "updateAlertState" }))
);

export const errorAlertState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "errorAlertState" }))
);

export const errorMessageState = create<any>()(
  devtools(
    persist(
      (set, get) => ({
        errorMessage: "",
      }),
      { name: "errorMessageState" }
    )
  )
);

export const activeStatusAlertState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "activeStatusAlertState" }))
);

export const activeConfirmationPopupState = create<boolean>()(
  devtools(
    persist((set, get) => false, { name: "activeConfirmationPopupState" })
  )
);

export const inActiveConfirmationPopupState = create<boolean>()(
  devtools(
    persist((set, get) => false, { name: "inActiveConfirmationPopupState" })
  )
);

export const deleteConfirmationPopupState = create<boolean>()(
  devtools(
    persist((set, get) => false, { name: "deleteConfirmationPopupState" })
  )
);
interface INumberOfStaffBedState {
  numberOfStaffBedList: GenericMasterData[];
}

export const numberOfStaffBedListState = create<INumberOfStaffBedState>()(
  devtools(
    persist((set, get) => ({ numberOfStaffBedList: [] }), {
      name: "numberOfStaffBedListState",
    })
  )
);

interface INumberOfEmployeeState {
  numberOfEmployeeList: GenericMasterData[];
}

export const numberOfEmployeeListState = create<INumberOfEmployeeState>()(
  devtools(
    persist((set, get) => ({ numberOfEmployeeList: [] }), {
      name: "numberOfEmployeeListState",
    })
  )
);

interface ILocationTypeState {
  locationTypeList: GenericMasterData[];
}

export const locationTypeListState = create<ILocationTypeState>()(
  devtools(
    persist((set, get) => ({ locationTypeList: [] }), {
      name: "locationTypeListState",
    })
  )
);

interface IWeekDayStartState {
  weekDayStartList: GenericMasterData[];
}

export const weekDayStartListState = create<IWeekDayStartState>()(
  devtools(
    persist((set, get) => ({ weekDayStartList: [] }), {
      name: "weekDayStartListState",
    })
  )
);

interface ITimeZoneListState {
  timeZoneList: TimeZoneDetail[];
}

export const timeZoneListState = create<ITimeZoneListState>()(
  devtools(
    persist((set, get) => ({ timeZoneList: [] }), {
      name: "timezonListState",
    })
  )
);

interface IDepartmentTypeListState {
  departmentTypeList: GenericMasterData[];
}

export const departmentTypeListState = create<IDepartmentTypeListState>()(
  devtools(
    persist((set, get) => ({ departmentTypeList: [] }), {
      name: "departmentTypeListState",
    })
  )
);

interface IRoleTypeListState {
  roleTypeList: GenericMasterData[];
}

export const roleTypeListState = create<IRoleTypeListState>()(
  devtools(
    persist((set, get) => ({ roleTypeList: [] }), {
      name: "roleTypeListState",
    })
  )
);

interface IShiftBreakTypeListState {
  shiftBreakTypeList: GenericMasterData[];
}

export const shiftBreakTypeListState = create<IShiftBreakTypeListState>()(
  devtools(
    persist((set, get) => ({ shiftBreakTypeList: [] }), {
      name: "shiftBreakTypeListState",
    })
  )
);

interface IStaffTypeListState {
  staffTypeList: GenericMasterData[];
}

export const staffTypeListState = create<IStaffTypeListState>()(
  devtools(
    persist((set, get) => ({ staffTypeList: [] }), {
      name: "staffTypeListState",
    })
  )
);

interface IPrimaryQualificationListState {
  primaryQualificationList: GenericMasterData[];
}

export const primaryQualificationListState =
  create<IPrimaryQualificationListState>()(
    devtools(
      persist((set, get) => ({ primaryQualificationList: [] }), {
        name: "primaryQualificationListState",
      })
    )
  );

export const currentActiveScreenState = create<string>()(
  devtools(persist((set, get) => "/", { name: "currentActiveScreenState" }))
);

interface IRecurrencePeriodListState {
  recurrencePeriodList: GenericMasterData[];
}

export const recurrencePeriodListState = create<IRecurrencePeriodListState>()(
  devtools(
    persist((set, get) => ({ recurrencePeriodList: [] }), {
      name: "recurrencePeriodListState",
    })
  )
);

interface IfacilityTypeListState {
  facilityTypeList: GenericMasterData[];
}

export const facilityTypeListState = create<IfacilityTypeListState>()(
  devtools(
    persist((set, get) => ({ facilityTypeList: [] }), {
      name: "facilityTypeListState",
    })
  )
);

interface ILineOfBusinessListState {
  lineOfBusinessList: GenericMasterData[];
}

export const lineOfBusinessListState = create<ILineOfBusinessListState>()(
  devtools(
    persist((set, get) => ({ lineOfBusinessList: [] }), {
      name: "lineOfBusinessListState",
    })
  )
);

interface IJobQualificationListState {
  jobQualificationList: JobQualification[];
}

export const jobQualificationListState = create<IJobQualificationListState>()(
  devtools(
    persist((set, get) => ({ jobQualificationList: [] }), {
      name: "jobQualificationListState",
    })
  )
);


export const apiAlertState = create<ApiAlert>()(
  devtools(
    persist(
      (set, get) => ({ alertMessage: "", serverity: "success", showAlert: false }),
      { name: "apiAlertState" }
    )
  )
);

export const activeLocationTabState = create<number>()(
  devtools(persist((set, get) => 0, { name: "activeLocationTabState" }))
);

export const departmentClearedState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "departmentClearedState" }))
);

export const locationClearedState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "locationClearedState" }))
);

export const unitClearedState = create<boolean>()(
  devtools(persist((set, get) => false, { name: "unitClearedState" }))
);