import { Box } from "@mui/material";
import { ReactNode, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currentActiveScreenState } from "../../libs/state-management-lib";

interface IDashBoardIconBox {
  children: ReactNode;
  navigationItem: string;
}

const dashBoardIconBoxStyle = {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  alignItems: "center",
  justifyContent: "center",
  color:"#0D80F2"
};

export const DashBoardIconBox = (props: IDashBoardIconBox) => {
  const navigate = useNavigate();
  const [currentNavigation, setCurrentNavigation] = useState<string>(currentActiveScreenState.getState());

  useLayoutEffect(() => {
    const currentActiveScreenStateSub = currentActiveScreenState.subscribe(
      async (state) => {
        if (!!state) {
          setCurrentNavigation(state);
        } else {
          setCurrentNavigation("/");
        }
      }
    );

    return () => {
      currentActiveScreenStateSub();
    };
  }, []);

  const handleClick = () => {
    currentActiveScreenState.setState(props.navigationItem);
    navigate(props.navigationItem);
  };

  return (
    <Box
      sx={{
        ...dashBoardIconBoxStyle,
        padding: "13px 8px 13px 8px",
        background:
          currentNavigation === props.navigationItem
            ? (theme) => theme.palette.primary.contrastText
            : "inherit",
        borderRadius: currentNavigation === props.navigationItem ? "8px" : "0px",
        P: {
          color:
            currentNavigation === props.navigationItem ? "#ffffff" : "inherit",
        },
        svg: {
          color:
            currentNavigation === props.navigationItem ? "#ffffff" : "inherit",
        },
        "&:hover": {
          cursor: "pointer",
          background: (theme) => theme.palette.primary.contrastText,
          borderRadius: "8px",
        },
        "&:hover P": {
          color: "#ffffff",
        },
        "&:hover svg": {
          color: "#ffffff",
        },
      }}
      onClick={handleClick}
    >
      {props.children}
    </Box>
  );
};

export const DashBoardIconBoxWithoutHover = (props: IDashBoardIconBox) => {
  const navigate = useNavigate();
  const [currentNavigation, setCurrentNavigation] = useState<string>(currentActiveScreenState.getState());

  useLayoutEffect(() => {
    const currentActiveScreenStateSub = currentActiveScreenState.subscribe(
      async (state) => {
        if (!!state) {
          setCurrentNavigation(state);
        } else {
          setCurrentNavigation("/");
        }
      }
    );

    return () => {
      currentActiveScreenStateSub();
    };
  }, []);

  const handleClick = () => {
    currentActiveScreenState.setState(props.navigationItem);
    navigate(props.navigationItem);
  };

  return (
    <Box sx={{ ...dashBoardIconBoxStyle, padding: "13px 0px 13px 0px" }}
    onClick={handleClick}
    >
      {props.children}
    </Box>
  );
};
