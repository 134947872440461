import { axiosInstance, EmptyLocationDetail, handleApiErrorAlert, handleApiSuccessAlert } from "../common-lib";
import {
  locationDetailListState,
  locationDetailState,
} from "../state-management-lib/locationState";
import { LocationDetail } from "../model-lib";

const organization_base_url = `${process.env.REACT_APP_PLATFORM_ORG_TRIAL_URL}/api/Location`;

export const getLocationById = async (locationId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetLocationById?locationId=${locationId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      locationDetailState.setState(response.data.data);
      return response.data.data;
    }

    return EmptyLocationDetail;
  } catch (error) {}
};

export const getAllLocationsByOrgId = async (orgId: string) => {
  try {
    let response = await axiosInstance.get(
      `${organization_base_url}/GetAllLocationsByOrgId?organizationId=${orgId}`
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      locationDetailListState.setState({
        locationDetailList: response.data.data,
      });
    }
  } catch (error) {}
};

export const deleteLocation = async (locationDetail: LocationDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/deleteLocation`,
      locationDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      handleApiSuccessAlert("Location removed successfully");
    }
  } catch (error) {
    handleApiErrorAlert(error);
  }
};

export const createLocation = async (locationDetail: LocationDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/CreateLocation`,
      locationDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getAllLocationsByOrgId(locationDetail.org_id);
      handleApiSuccessAlert("Location created successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const updateLocation = async (locationDetail: LocationDetail) => {
  try {
    let response = await axiosInstance.post(
      `${organization_base_url}/updateLocation`,
      locationDetail
    );

    if (!!response && !!response.data && response.data.statusCode === 200) {
      await getAllLocationsByOrgId(locationDetail.org_id);
      handleApiSuccessAlert("Location updated successfully");
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
