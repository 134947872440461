import { Box, FormControlLabel, MenuItem, Switch } from "@mui/material";
import {
  ContainedButton,
  DropdownWithBorder,
  OutlinedButton,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import { ShareShiftCheckBox } from "../../atom/checkbox/shareShiftCheckbox";

export const NotificationsSoundSetting = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "calc(100%-48px)",
            height: "91% ",
            display: "flex",
            flexDirection: "column",
            padding: "24px 24px 0px 24px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "42%",
              minWidth: "598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Notification source"} color="#17191C" />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "16px !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#000000DE",
                  },
                }}
                control={
                  <Switch
                    sx={{
                      width: "58px",
                      ".Mui-checked": {
                        color: "#0D80F2 !important",
                      },
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#0D80F2 !important",
                      },
                    }}
                    checked={true}
                  />
                }
                label="Push notifications"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "16px !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#000000DE",
                  },
                }}
                control={
                  <Switch
                    sx={{
                      width: "58px",
                      ".Mui-checked": {
                        color: "#0D80F2 !important",
                      },
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#0D80F2 !important",
                      },
                    }}
                    checked={false}
                  />
                }
                label="Email"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "16px !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#000000DE",
                  },
                }}
                control={
                  <Switch
                    sx={{
                      width: "58px",
                      ".Mui-checked": {
                        color: "#0D80F2 !important",
                      },
                      ".Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#0D80F2 !important",
                      },
                    }}
                    checked={true}
                  />
                }
                label="SMS"
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: "42%",
              minWidth: "598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Notification type"} color="#17191C" />
            <Box
              sx={{
                Width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box>
                <FormControlLabel
                  sx={{
                    alignContent: "center",
                    gap: "8px",
                    paddingLeft: "15px",
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#000000DE",
                    },
                  }}
                  key={0}
                  control={
                    <ShareShiftCheckBox
                      sx={{
                        span: {
                          boxShadow:
                            "inset 0 0 0 3px #666666, inset 03px 0 #666666",
                          width: "21px",
                          height: "21px",
                        },
                      }}
                      value={0}
                    />
                  }
                  label={"All notifications"}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "34px",
                }}
              >
                <FormControlLabel
                  sx={{
                    alignContent: "center",
                    gap: "8px",
                    paddingLeft: "15px",
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#000000DE",
                    },
                  }}
                  key={0}
                  control={
                    <ShareShiftCheckBox
                      sx={{
                        span: {
                          boxShadow:
                            "inset 0 0 0 3px #666666, inset 03px 0 #666666",
                          width: "18px",
                          height: "18px",
                          '&::before': {
                            display: 'block',
                            width: 18,
                            height: 18,
                            backgroundImage:
                              "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                              " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                            content: '""',
                          },
                        },
                      }}
                      value={0}
                    />
                  }
                  label={"About new tasks"}
                />
                <FormControlLabel
                  sx={{
                    alignContent: "center",
                    gap: "8px",
                    paddingLeft: "15px",
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#000000DE",
                    },
                  }}
                  key={0}
                  control={
                    <ShareShiftCheckBox
                      sx={{
                        span: {
                          boxShadow:
                            "inset 0 0 0 3px #666666, inset 03px 0 #666666",
                          width: "18px",
                          height: "18px",
                          '&::before': {
                            display: 'block',
                            width: 18,
                            height: 18,
                            backgroundImage:
                              "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                              " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                            content: '""',
                          },
                        },
                      }}
                      value={0}
                    />
                  }
                  label={"About new messages"}
                />
                <FormControlLabel
                  sx={{
                    alignContent: "center",
                    gap: "8px",
                    paddingLeft: "15px",
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#000000DE",
                    },
                  }}
                  key={0}
                  control={
                    <ShareShiftCheckBox
                      sx={{
                        span: {
                          boxShadow:
                            "inset 0 0 0 3px #666666, inset 03px 0 #666666",
                          width: "18px",
                          height: "18px",
                          '&::before': {
                            display: 'block',
                            width: 18,
                            height: 18,
                            backgroundImage:
                              "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                              " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                              "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                            content: '""',
                          },
                        },
                      }}
                      value={0}
                    />
                  }
                  label={"About personal mentions"}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "42%",
              minWidth: "598px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              gap: "16px",
              border: "1px solid #F1F2F4",
            }}
          >
            <Typography18_600 text={"Notification sound"} color="#17191C" />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <Typography12_600 text="Sound" color="#737F8C" />
              <DropdownWithBorder value={1} handleChange={(e: any) => {}}>
                <MenuItem value={1}>
                  <Typography16_400 text={"Ding"} color="#031A30" />
                </MenuItem>
                <MenuItem value={2}>
                  <Typography16_400 text={"Cuckoo"} color="#031A30" />
                </MenuItem>
                <MenuItem value={3}>
                  <Typography16_400 text={"Ring"} color="#031A30" />
                </MenuItem>
                <MenuItem value={4}>
                  <Typography16_400 text={"Bell"} color="#031A30" />
                </MenuItem>
                <MenuItem value={5}>
                  <Typography16_400 text={"Dot"} color="#031A30" />
                </MenuItem>
                <MenuItem value={6}>
                  <Typography16_400 text={"Fly"} color="#031A30" />
                </MenuItem>
                <MenuItem value={7}>
                  <Typography16_400 text={"Bing"} color="#031A30" />
                </MenuItem>
              </DropdownWithBorder>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "calc(100%-44px)",
            height: "9%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 16px 0px 28px",
            gap: "16px",
            borderTop: "1px solid #CFE5FC",
          }}
        >
          <OutlinedButton
            text={"Cancel"}
            sx={{ height: "40px", width: "82px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
          <ContainedButton
            text={"Save changes"}
            sx={{ height: "40px", padding: "0px 16px", minWidth: "132px" }}
            handleClick={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Box>
      </Box>
    </>
  );
};
