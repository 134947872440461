import {
  Box,
  FormControlLabel,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import {
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  MultiSelectDropdown,
  PlatformTextField,
  TextAreaField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography14_400,
  Typography18_600,
} from "../../atom";
import { useState } from "react";
import {
  GenericMasterData,
  OrgRole,
} from "../../../libs/model-lib";
import {
  orgRoleListState,
  staffTypeListState,
} from "../../../libs/state-management-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { getErrorDetail } from "../../../libs/common-lib";
import { useDepartmentDetailContext } from "../../../libs/context-lib/departmentDetailContext";

interface IDepartmentStaffingForm {
  errorMessage: any[];
}

export const DepartmentStaffingForm = (props: IDepartmentStaffingForm) => {
  const { departmentDetail, setDepartmentDetail } =
    useDepartmentDetailContext();

  const [staffTypeList, setStaffTypeList] = useState<GenericMasterData[]>(
    staffTypeListState.getState().staffTypeList
  );
  const [orgRoleList, setOrgRoleList] = useState<OrgRole[]>(
    orgRoleListState.getState().orgRoleList
  );

  const handleStaffTypeCheck = (event: any) => {
    let staffType = departmentDetail.staff_type_ids.slice(0);

    if (event.target.checked) {
      staffType.push(parseInt(event.target.value));
    } else {
      staffType = staffType.filter((cc) => cc != parseInt(event.target.value));
    }

    setDepartmentDetail({
      ...departmentDetail,
      staff_type_ids: staffType,
    });
  };

  const handleRoleTypeChange = (e: any) => {
    setDepartmentDetail({ ...departmentDetail, org_role_ids: e.target.value });
  };

  const handleRoleTypeRemove = (e: any) => {
    let roleList = departmentDetail.org_role_ids.filter((cc) => cc != e);
    setDepartmentDetail({ ...departmentDetail, org_role_ids: roleList });
  };

  const checkStaffTypeAvailable = (staffTypeId: number) => {
    if (
      !!departmentDetail.staff_type_ids &&
      departmentDetail.staff_type_ids.length > 0 &&
      departmentDetail.staff_type_ids.filter((dd) => dd == staffTypeId).length >
        0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleNumberofBedsChange = (event: any) => {
    const inputValue = event.target.value;
    const numberOfBeds = inputValue.replace(/\D/g, "");
    if (numberOfBeds.length <= 4) {
      setDepartmentDetail({
        ...departmentDetail,
        number_of_beds: numberOfBeds,
      });
    }
  };

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Talent type" color="#17191C" />
          </Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={departmentDetail.staff_type_ids}
          >
            {staffTypeList.map((cc) => (
              <FormControlLabel
                sx={{
                  alignContent: "center",
                  gap: "8px",
                }}
                key={cc.id}
                control={
                  <FormCheckbox
                    checked={checkStaffTypeAvailable(cc.id)}
                    value={cc.id}
                    onChange={handleStaffTypeCheck}
                  />
                }
                label={cc.name}
              />
            ))}
          </RadioGroup>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Roles and beds details" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Roles" color="#737F8C" />
                <MultiSelectDropdown
                  placeholder="Select roles"
                  handleChange={handleRoleTypeChange}
                  value={departmentDetail.org_role_ids}
                  label={""}
                  listItems={orgRoleList}
                  handleRemove={handleRoleTypeRemove}
                  labelValue="role_name"
                >
                  <MenuItem value={""} disabled>
                    Select roles
                  </MenuItem>
                  {orgRoleList.map((cc: OrgRole) => (
                    <MenuItem value={cc.id} key={cc.id}>
                      {cc.role_name}
                    </MenuItem>
                  ))}
                </MultiSelectDropdown>
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Number of beds" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter number of beds"}
                  value={String(departmentDetail.number_of_beds)}
                  handleChange={handleNumberofBedsChange}
                  errorMessage={getErrorDetail(
                    "number_of_beds",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "100%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Notes" color="#737F8C" />
                <TextAreaField
                  Label="Add notes..."
                  value={departmentDetail.description}
                  handleChange={(e: any) => {
                    setDepartmentDetail({
                      ...departmentDetail,
                      description: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "description",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
              <Typography14_400
                text="Notes will be displayed to other users, agency partners, and talent to provide details about the department"
                color="#737F8C"
              />
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
