import { Box, Typography } from "@mui/material";
import {
  PlatformLoginTextField,
  Typography14_500,
  Typography16_400,
  Typography24_500,
} from "../../atom";
import { useLayoutEffect, useRef, useState } from "react";
import { CodeVerifyForm } from "./codeVerifyForm";
import { useNavigate } from "react-router-dom";
import { SignUpContainedButton } from "../../atom/buttons/signupContainedButton";
import {
  loadingIconState,
  userDetailstate,
} from "../../../libs/state-management-lib";
import {
  generateLoginToken,
  generateOTP,
  getOrganizationByUserId,
  getUserDetail,
} from "../../../libs/services-lib";
import { LoginDetail, OrganizationDetail, UserDetail } from "../../../libs/model-lib";
import Joi from "joi";
import { EmptyGuid,  getErrorDetail, handleApiErrorAlert } from "../../../libs/common-lib";
import { LoginDetailContext } from "../../../libs/context-lib/loginDetailContext";

export const SignInSetting = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any[]>([]);
  const [signInMenu, setSignInMenu] = useState<string>("signInSetting");
  const [signInTabvalue, setSignInTabvalue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setSignInTabvalue(newValue);
  };
  const [loginDetail, setLoginDetail] = useState<LoginDetail>({
    userName: "",
    password: "",
    mobileNumber: "",
    otp: "",
  });
  const timerId = useRef<any>();

  useLayoutEffect(() => {
    const userDetailStateSub = userDetailstate.subscribe(async (state) => {
      await getOrganizationByUserId(state.id);
    });

    return () => {
      userDetailStateSub();
    };
  }, []);

  const emailValidateschema = Joi.object<LoginDetail>({
    userName: Joi.string().required().max(255).email({ tlds: false }).messages({
      "string.empty": `This field is mandatory`,
      "string.email": `The entered value is not an email`,
      "string.max": `The maximum character limit should be 255`,
    }),
    password: Joi.string().allow(""),
    mobileNumber: Joi.string().allow(""),
    otp: Joi.string().allow(""),
  }).options({ abortEarly: false });

  const codeValidateschema = Joi.object<LoginDetail>({
    userName: Joi.string().allow(""),
    password: Joi.string().allow(""),
    mobileNumber: Joi.string().allow(""),
    otp: Joi.string().min(6).max(6).required().messages({
      "string.empty": `code cannot be an empty field`,
      "string.min": `The code is not valid. Try again.`,
      "string.max": `The code is not valid. Try again.`,
    }),
  }).options({ abortEarly: false });

  const handleEmailClick = async () => {
    if (signInTabvalue == 0) {
      const result = emailValidateschema.validate(loginDetail);
      let errorMessage: any[] = [];

      if (!!result.error && !!result.error.details) {
        result.error.details.forEach((cc) => {
          let field = cc.path[0];
          if (cc.path.length > 1) {
            field = cc.path.join("$");
          }
          errorMessage.push({ field: field, errorMessage: cc.message });
        });

        setErrorMessage(errorMessage);
        return;
      }
      setErrorMessage([]);
      loadingIconState.setState(true);
      await generateOTP({
        userName: loginDetail.userName,
      });
      loadingIconState.setState(false);
      loginDetail.otp = "";
      setSignInMenu("emailVerifyForm");
    }
  };

  const handleVerifyCodeClick = async () => {
    const result = codeValidateschema.validate(loginDetail);
    let errorMessage: any[] = [];

    if (!!result.error && !!result.error.details) {
      result.error.details.forEach((cc) => {
        let field = cc.path[0];
        if (cc.path.length > 1) {
          field = cc.path.join("$");
        }
        errorMessage.push({ field: field, errorMessage: cc.message });
      });

      setErrorMessage(errorMessage);
      return;
    }
    setErrorMessage([]);
    loadingIconState.setState(true);
    try {
      await generateLoginToken({
        userName: loginDetail.userName,
        otp: loginDetail.otp,
      });
      handleDashboardNavigate();
    } catch (error) {
      loadingIconState.setState(false);
    }
  };

  const handleDashboardNavigate = async () => {
    let userDetail: UserDetail = await getUserDetail();
    if (!!userDetail && !!userDetail.id && userDetail.id != EmptyGuid) {
      try {
        let orgData: OrganizationDetail = await getOrganizationByUserId(
          userDetail.id
        );

        if (!!orgData && !!orgData.id && orgData.id != EmptyGuid) {
          navigate("/settings/organization");
        } else {
          handleApiErrorAlert("Error in sign in. Please contact support team");
          navigate("/");
        }
      } catch (error) {
        handleApiErrorAlert("Error in sign in. Please contact support team");
        navigate("/");
      }
    }
    loadingIconState.setState(false);
  };

  const handleResendCodeClick = async () => {
    loadingIconState.setState(true);
    await generateOTP({
      userName: loginDetail.userName,
    });
    loadingIconState.setState(false);
  };

  const handleSignUpClick = () => {
    navigate("/SignUp");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <Box sx={{ flexDirection: "row", gap: "4px" }}>
          <img
            src="/images/common/logo.png"
            style={{ width: "160px", height: "40px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "24px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          {signInMenu == "signInSetting" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                <Typography24_500 text="Login" color="#000000DE" />
                <Typography16_400
                  text="Please, provide your email to receive an OTP"
                  color="#00000099"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "24px",
                  padding: "10px",
                  width: "calc(100% - 20px)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  <>
                    <Typography14_500 text="Email" color="#000000" />
                    <PlatformLoginTextField
                      sx={{
                        "&:hover fieldset": {
                          border: "1px solid #0000003B !important",
                          borderRadius: "8px",
                        },
                      }}
                      placeholder="e.g. jhondoe@gmail.com"
                      value={loginDetail.userName}
                      handleChange={(e: any) => {
                        setLoginDetail({
                          ...loginDetail,
                          userName: e.target.value,
                        });
                      }}
                      errorMessage={getErrorDetail("userName", errorMessage)}
                    />
                  </>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "calc(100% - 20px)",
                  padding: "10px",
                }}
              >
                <SignUpContainedButton
                  text="Continue"
                  disabled={!!!loginDetail.userName}
                  handleClick={handleEmailClick}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={handleSignUpClick}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    color: "#00000099",
                  }}
                >
                  Don’t have a ShiftNex account yet?{" "}
                  <span
                    style={{
                      fontWeight: "500",
                      color: "#1F5DA5",
                      lineHeight: "22px",
                      letterSpacing: "0.1px",
                    }}
                  >
                    Sign Up
                  </span>
                </Typography>
              </Box>
            </>
          )}
          {signInMenu == "emailVerifyForm" && (
            <LoginDetailContext.Provider
              value={{ loginDetail, setLoginDetail }}
            >
              <CodeVerifyForm
                errorMessage={errorMessage}
                handleVerifyCodeClick={handleVerifyCodeClick}
                handleResendCodeClick={handleResendCodeClick}
              />
            </LoginDetailContext.Provider>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.17px",
            color: "#00000099",
          }}
        >
          By using Shiftnex you agree to the{" "}
          <span
            style={{
              fontWeight: "500",
              color: "#1F5DA5",
              lineHeight: "22px",
              letterSpacing: "0.1px",
              cursor: "pointer",
            }}
          >
            Terms of service
          </span>{" "}
          and{" "}
          <span
            style={{
              fontWeight: "500",
              color: "#1F5DA5",
              lineHeight: "22px",
              letterSpacing: "0.1px",
              cursor: "pointer",
            }}
          >
            Privacy policy
          </span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.17px",
            color: "#00000099",
          }}
        >
          Version 1.0.0
        </Typography>
      </Box>
    </>
  );
};
