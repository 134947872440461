import { Box, Link, MenuItem, Typography } from "@mui/material";
import {
  LableTextFieldBox,
  MultiSelectDropdown,
  Typography12_600,
  Typography14_400,
  Typography16_600,
} from "../../atom";
import { useEffect, useState } from "react";
import {
  agencyMappingState,
  locationDetailListState,
  orgDepartmentListState,
} from "../../../libs/state-management-lib";
import {
  DepartmentDetail,
  LocationDetail,
} from "../../../libs/model-lib";
import { useNavigate } from "react-router-dom";
import { useAgencyMappingDetailContext } from "../../../libs/context-lib/staffingPartnerContext";

export const StaffingPartnerLocationForm = () => {
  const [locationList, setLocationList] = useState<LocationDetail[]>(
    locationDetailListState.getState().locationDetailList
  );
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const navigate = useNavigate();
  const { agencyMappingDetail, setAgencyMappingDetail } =
    useAgencyMappingDetailContext();

  useEffect(() => {
    let agencyMappingStateDetail = agencyMappingState.getState();
    agencyMappingStateDetail.department_ids =
      agencyMappingDetail.department_ids;
    agencyMappingStateDetail.location_ids = agencyMappingDetail.location_ids;
    agencyMappingState.setState(agencyMappingStateDetail);
  }, [agencyMappingDetail]);

  const handleLocationChange = (e: any) => {
    setAgencyMappingDetail({
      ...agencyMappingDetail,
      location_ids: e.target.value,
    });

    if (!!e.target.value && e.target.value.length > 0) {
      let deptList = orgDepartmentListState
        .getState()
        .departmentDetailList.filter((cc: DepartmentDetail) =>
          e.target.value.find((dd: string) => dd == cc.location_Id)
        );

      setDepartmentList(deptList);
    } else {
      setDepartmentList([]);
      setAgencyMappingDetail({
        ...agencyMappingDetail,
        department_ids: [],
      });
    }
  };

  const handleDepartmentChange = (e: any) => {
    setAgencyMappingDetail({
      ...agencyMappingDetail,
      department_ids: e.target.value,
    });
  };

  const handleLocationRemove = (e: any) => {
    if (
      agencyMappingDetail.location_ids.filter(
        (location: string) => location != e
      ).length > 0
    ) {
      let deptList = orgDepartmentListState
        .getState()
        .departmentDetailList.filter((cc: DepartmentDetail) =>
          agencyMappingDetail.location_ids
            .filter((location: string) => location != e)
            .find((dd: string) => dd == cc.location_Id)
        );

      let selectedDepartment: string[] = [];

      agencyMappingDetail.department_ids.forEach((element) => {
        if (deptList.find((cc) => cc.id == element)) {
          selectedDepartment.push(element);
        }
      });

      setDepartmentList(deptList);

      setAgencyMappingDetail({
        ...agencyMappingDetail,
        location_ids: agencyMappingDetail.location_ids.filter(
          (location: string) => location != e
        ),
        department_ids: selectedDepartment,
      });
    } else {
      setDepartmentList([]);
      setAgencyMappingDetail({
        ...agencyMappingDetail,
        department_ids: [],
        location_ids: [],
      });
    }
  };

  const handleDepartmentRemove = (e: any) => {
    setAgencyMappingDetail({
      ...agencyMappingDetail,
      department_ids: agencyMappingDetail.department_ids.filter(
        (dept: string) => dept != e
      ),
    });
  };

  const handleScheduleClick = () => {
    navigate("/schedule");
  };

  return (
    <>
      <Box
        sx={{
          width: "calc(99% - 32px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "16px",
          border: "1px solid #F1F2F4",
          borderRadius: "8px",
          padding: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography16_600 text={"Locations"} color="#17191C" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Typography14_400
              text="Select the locations, departments and sub-departments where you want
            the"
              color="#17191C"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
              }}
            >
              <Typography14_400
                text="agency to fill the shifts. If skipped, this information can be
            added in"
                color="#17191C"
              />
              <Link onClick={handleScheduleClick}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    textDecoration: "underline",
                    color: "#0D80F2",
                    cursor: "pointer",
                  }}
                >
                  Schedule tab.
                </Typography>
              </Link>
              {/* <Typography14_400
                text="Schedule tab."
                color="#0D80F2"
                sx={{ cursor: "pointer", textDecorationLine: "underline" }}
              /> */}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexdirection: "row",
            gap: "16px",
          }}
        >
          <Box sx={{ width: "50%", display: "flex", flexdirection: "row" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Locations" color="#737F8C" />
              <MultiSelectDropdown
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                disabled
                placeholder="Select locations"
                handleChange={handleLocationChange}
                value={agencyMappingDetail.location_ids}
                label={""}
                listItems={locationList}
                handleRemove={handleLocationRemove}
              >
                <MenuItem value={0} disabled>
                  Select locations
                </MenuItem>
                {locationList.map((cc: LocationDetail) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    {cc.name}
                  </MenuItem>
                ))}
              </MultiSelectDropdown>
            </LableTextFieldBox>
          </Box>
          <Box sx={{ width: "50%" }}>
            <LableTextFieldBox>
              <Typography12_600 text="Departments" color="#737F8C" />
              <MultiSelectDropdown
                sx={{
                  "&.Mui-disabled": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ABB2BA !important",
                    },
                  },
                }}
                disabled
                placeholder="Select departments"
                handleChange={handleDepartmentChange}
                value={agencyMappingDetail.department_ids}
                label={""}
                listItems={departmentList}
                handleRemove={handleDepartmentRemove}
              >
                <MenuItem value={0} disabled>
                  Select departments
                </MenuItem>
                {departmentList.map((cc: DepartmentDetail) => (
                  <MenuItem value={cc.id} key={cc.id}>
                    {cc.name}
                  </MenuItem>
                ))}
              </MultiSelectDropdown>
            </LableTextFieldBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};
