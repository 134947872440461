import { Box } from "@mui/material";
import {
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import {
  departmentClearedState,
  locationDetailState,
} from "../../../libs/state-management-lib";
import { useLayoutEffect, useState } from "react";
import { LocationDetail } from "../../../libs/model-lib";
import { getErrorDetail } from "../../../libs/common-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { useDepartmentDetailContext } from "../../../libs/context-lib/departmentDetailContext";

interface IDepartmentContactInfoForm {
  errorMessage: any[];
}
export const DepartmentContactInfoForm = (
  props: IDepartmentContactInfoForm
) => {
  const { departmentDetail, setDepartmentDetail } =
    useDepartmentDetailContext();

  const [locationDetail, setLocationDetail] = useState<LocationDetail>(
    locationDetailState.getState()
  );

  const [sameContactInfo, setSameContactInfo] = useState<boolean>(false);

  useLayoutEffect(() => {
    const locationDetailStateSub = locationDetailState.subscribe(
      async (state) => {
        setLocationDetail(state);
      }
    );

    const departmentClearedStateSub = departmentClearedState.subscribe(
      async (state) => {
        setSameContactInfo(false);
      }
    );

    return () => {
      locationDetailStateSub();
      departmentClearedStateSub();
    };
  }, []);

  const handleChecked = (e: any) => {
    if (e.target.checked) {
      setDepartmentDetail((prevState) => ({
        ...prevState,
        departmentContactDetails: {
          ...prevState.departmentContactDetails,
          name: locationDetail.locationContactDetails.name,
          title: locationDetail.locationContactDetails.title,
          phone_number: locationDetail.locationContactDetails.phone_number,
          email: locationDetail.locationContactDetails.email,
        },
      }));

      setSameContactInfo(e.target.checked);
    } else {
      setDepartmentDetail((prevState) => ({
        ...prevState,
        departmentContactDetails: {
          ...prevState.departmentContactDetails,
          name: "",
          title: "",
          phone_number: "",
          email: "",
        },
      }));

      setSameContactInfo(false);
    }
  };

  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600 text="Main department contact" color="#17191C" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <FormCheckbox
              sx={{
                paddingLeft: "0px !important",
              }}
              onChange={handleChecked}
              checked={sameContactInfo}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography16_400 text="Same as location" color="#17191C" />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter name"}
                  value={departmentDetail.departmentContactDetails.name}
                  handleChange={(e: any) => {
                    setDepartmentDetail((prevState) => ({
                      ...prevState,
                      departmentContactDetails: {
                        ...prevState.departmentContactDetails,
                        name: e.target.value,
                      },
                    }));
                    setSameContactInfo(false);
                  }}
                  errorMessage={getErrorDetail(
                    "departmentContactDetails$name",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Title" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter title"}
                  value={departmentDetail.departmentContactDetails.title}
                  handleChange={(e: any) => {
                    setDepartmentDetail((prevState) => ({
                      ...prevState,
                      departmentContactDetails: {
                        ...prevState.departmentContactDetails,
                        title: e.target.value,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail(
                    "departmentContactDetails$title",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Phone number" color="#737F8C" />
                <PhoneNumberTextField
                  placeholder="Enter phone number"
                  value={departmentDetail.departmentContactDetails.phone_number}
                  handleChange={(e: any) => {
                    setDepartmentDetail((prevState) => ({
                      ...prevState,
                      departmentContactDetails: {
                        ...prevState.departmentContactDetails,
                        phone_number: e,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail(
                    "departmentContactDetails$phone_number",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Email" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter email"}
                  value={departmentDetail.departmentContactDetails.email}
                  handleChange={(e: any) => {
                    setDepartmentDetail((prevState) => ({
                      ...prevState,
                      departmentContactDetails: {
                        ...prevState.departmentContactDetails,
                        email: e.target.value,
                      },
                    }));
                  }}
                  errorMessage={getErrorDetail(
                    "departmentContactDetails$email",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
