import {
  Box,
  Chip,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  FilterIcon,
  MoreVerticalIcon,
  SearchTextField,
  StarIcon,
  Typography14_500,
  Typography18_600,
} from "../../atom";
import { useState } from "react";
import { MarketPlacefilterDialog } from "./marketPlaceFilterDialog";
import { grey } from "@mui/material/colors";
import React from "react";
import { SplitButton } from "../../atom/buttons/splitButton";

export const MarketPlaceDashboard = () => {
  const [filter, setFilter] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  const chipOnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== "bottom-start" || !prev);
    setPlacement("bottom-start");
  };

  const handleShowFilterClick = () => {
    //setFilter(true);
  };

  const handleCancelFilterClick = () => {
    setFilter(false);
  };

  const rows = [
    {
      id: "1",
      logo: "/images/common/StaffingPartner.png",
      agencyName: "Zap Nurse Agency",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "WA: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.3",
      connection: "Connected",
    },
    {
      id: "2",
      logo: "/images/common/marketPlaceLogo1.svg",
      agencyName: "Harborview Medical Associates",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: Albany, Amsterdam, Buffalo; WA: Aberdeen, Arlington;",
      email: "jacob.espinoza@gmail.com",
      rating: "4.7",
      connection: "Connected",
    },
    {
      id: "3",
      logo: "/images/common/marketPlaceLogo2.svg",
      agencyName: "Seattle Wellness Institute",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.8",
      connection: "Not connected",
    },
    {
      id: "4",
      logo: "/images/common/marketPlaceLogo3.svg",
      agencyName: "Ballard Community Clinic3",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: Buffalo, Canandaigua, Gloversville, Jamestown, Lackawanna",
      email: "jacob.espinoza@gmail.com",
      rating: "4.4",
      connection: "Not connected",
    },
    {
      id: "5",
      logo: "/images/common/marketPlaceLogo4.svg",
      agencyName: "Capitol Hill Medical Center",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "WA: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.8",
      connection: "Pending",
    },
    {
      id: "6",
      logo: "/images/common/marketPlaceLogo5.svg",
      agencyName: "Zap Nurse Agency",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "WA: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.9",
      connection: "Disconnected",
    },
    {
      id: "7",
      logo: "/images/common/marketPlaceLogo1.svg",
      agencyName: "Harborview Medical Associates",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: Albany, Amsterdam, Buffalo; WA: Aberdeen, Arlington;",
      email: "jacob.espinoza@gmail.com",
      rating: "4.7",
      connection: "Connected",
    },
    {
      id: "8",
      logo: "/images/common/marketPlaceLogo2.svg",
      agencyName: "Seattle Wellness Institute",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.8",
      connection: "Not connected",
    },
    {
      id: "9",
      logo: "/images/common/marketPlaceLogo3.svg",
      agencyName: "Ballard Community Clinic3",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "NY: Buffalo, Canandaigua, Gloversville, Jamestown, Lackawanna",
      email: "jacob.espinoza@gmail.com",
      rating: "4.4",
      connection: "Not connected",
    },
    {
      id: "10",
      logo: "/images/common/marketPlaceLogo4.svg",
      agencyName: "Capitol Hill Medical Center",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "WA: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.8",
      connection: "Pending",
    },
    {
      id: "11",
      logo: "/images/common/marketPlaceLogo5.svg",
      agencyName: "Zap Nurse Agency",
      headquarters: "Tacoma, WA",
      linesOfBusiness: "Acute Care, Ambulatory Care, Behavioral Care",
      operatingSince: "15 May 2002",
      employeeCount: "51-100",
      region: "WA: All",
      email: "jacob.espinoza@gmail.com",
      rating: "4.9",
      connection: "Disconnected",
    },
  ];

  const option = ["Connect", "Cancel invitation", "Disconnect"];

  return (
    <>
      {filter && (
        <MarketPlacefilterDialog
          handleShowFilterClick={handleShowFilterClick}
          handleCancelFilterClick={handleCancelFilterClick}
        />
      )}

      <Box
        sx={{
          width: "calc(100%-48px)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",         
          overflow: "hidden",
          padding: "24px",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height:"40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography18_600 text="Marketplace" color="#17191C" />
          </Box>
          <Box
            sx={{
              width: "70%",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <SplitButton options={option} name={"Actions"} handleSplitButtonClick={() => {}}/>
            <SearchTextField
            sx={{
              width:"320px"
            }}
              placeholder={"Search"}
              value={""}
              handleChange={(e: any) => {}}
            />
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                //cursor: "pointer",
              }}
              onClick={handleShowFilterClick}
            >
              <FilterIcon
                sx={{ 
                  //color: "#0D80F2", 
                  color:"#ABB2BA",
                  marginTop: ".75%", paddingLeft: ".5%" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRadius: "8px",
              width: "100%",
              height: "90%",
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                width: "6px",
                height: "6px",
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: "32px",
                backgroundColor: grey["400"],
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                {
                  backgroundColor: grey["500"],
                },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                {
                  backgroundColor: grey["500"],
                },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                {
                  backgroundColor: grey["500"],
                },
              "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                backgroundColor: "#0000000",
              },
            }}
          >
            <DataGrid
              sx={{
                width: "100%",
                height: "100%",
                
                "& .theme--header": {
                  backgroundColor: "#F9FAFA",
                  color:"#00000099",
                  fontSize:"14px",
                  fontWeight:"400",
                  lineHeight:"24px",
                  letterSpacing:"0.17px"
                },
                "& .MuiDataGrid-row": {
                  borderBottom: "1px solid #E3E5E8",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#F5FAFE",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  background: "#F9FAFA",
                },
                "& .MuiCheckbox-root": {
                  color: "#ABB2BA !important",
                },
                "& .Mui-checked": {
                  color: "#0D80F2 !important",
                },
                "& .MuiDataGrid-scrollbarFiller": {
                  backgroundColor: "#F9FAFA",
                },
                "& .MuiDataGrid-root .MuiDataGrid-filler": {
                  backgroundColor: "#F9FAFA",
                },
                border: "1px solid #E3E5E8",
              }}
              key={0}
              rows={rows}
              columns={[
                {
                  field: "agencyName",
                  headerName: "Agency name",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 350,
                  renderCell: (params: any) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "8px 0px",
                          height: "32px",
                          justifyContent: "flex-start",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={params.row.logo}
                          style={{ width: "40px", height: "40px" }}
                        />
                        <Typography14_500
                          sx={{ paddingLeft: "5px", letterSpacing:"0.1px" }}
                          text={params.row.agencyName} color="#0D80F2"
                        />
                      </Box>
                    );
                  },
                },
                {
                  field: "headquarters",
                  headerName: "Headquarters",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 150,
                },
                {
                  field: "linesOfBusiness",
                  headerName: "Lines of business",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 200,
                },
                {
                  field: "operatingSince",
                  headerName: "Operating since",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 150,
                },
                {
                  field: "employeeCount",
                  headerName: "Employee count",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 150,
                },
                {
                  field: "region",
                  headerName: "Region",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 200,
                },
                {
                  field: "email",
                  headerName: "Email",
                  headerClassName: "theme--header",
                  flex: 1,
                },
                {
                  field: "rating",
                  headerName: "Rating",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 110,
                  renderCell: (params: any) => {
                    const Icons = () => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "88px",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <StarIcon sx={{ paddingLeft: "25px" }} />
                        {params.row.rating}
                        <MoreVerticalIcon sx={null} />
                      </Box>
                    );
                    return (
                      <Chip
                        icon={<Icons />}
                        sx={{
                          height: "30px",
                          width: "88px",
                          background: "#FF99000D",
                          borderRadius: "4px",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#000000DE",
                        }}
                      />
                    );
                  },
                },
                {
                  field: "connection",
                  headerName: "Connection",
                  headerClassName: "theme--header",
                  flex: 1,
                  maxWidth: 150,
                  renderCell: (params: any) => {
                    if (params.row.connection === "Connected") {
                      return (
                        <Chip
                          label={params.row.connection}
                          sx={{
                            height: "22px",
                            background: "#EBFAEE",
                            borderRadius: "4px",
                            color: "#29A347",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                          onClick={chipOnClick}
                        />
                      );
                    } else if (params.row.connection === "Not connected") {
                      return (
                        <Chip
                          label={params.row.connection}
                          sx={{
                            height: "22px",
                            background: "#FAFBFC",
                            borderRadius: "4px",
                            color: "#00000099",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                          onClick={chipOnClick}
                        />
                      );
                    } else if (params.row.connection === "Pending") {
                      return (
                        <Chip
                          label={params.row.connection}
                          sx={{
                            height: "22px",
                            background: "#FF99000D",
                            borderRadius: "4px",
                            color: "#FF9900",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                          onClick={chipOnClick}
                        />
                      );
                    } else if (params.row.connection === "Disconnected") {
                      return (
                        <Chip
                          label={params.row.connection}
                          sx={{
                            height: "22px",
                            background: "#C2380A0D",
                            borderRadius: "4px",
                            color: "#C2380A",
                            fontWeight: "700",
                            fontSize: "13px",
                          }}
                          onClick={chipOnClick}
                        />
                      );
                    }
                  },
                },
              ]}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterValues: [],
                  },
                },
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 20, 30]}
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              checkboxSelection
            />
          </Box>
        </Box>
      </Box>
      <Popper open={open} anchorEl={anchorEl} transition placement={placement}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                width: "180px",
                height: "52px",
                boxShadow:
                  "0px 3px 14px 2px #0000001F, box-shadow: 0px 8px 10px 1px #00000024, box-shadow: 0px 5px 5px -3px #00000033",
              }}
            >
              <Typography sx={{ p: 2 }}>Connect</Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
