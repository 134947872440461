import {
  orgRoleListState,
} from "../../../libs/state-management-lib";
import { CalendarShareShiftDetail } from "../../atom/box/calendarBoxes/calendarShareShiftDetailBox";
import { format } from "date-fns";
import { Shift } from "../../../libs/model-lib";

interface ICalendarShareShiftOpenShiftsContainer {
  openShiftDetail: Shift;
}

export const CalendarShareShiftOpenShiftsContainer = (
  props: ICalendarShareShiftOpenShiftsContainer
) => {
  const getOpenShiftType = (shiftType: number) => {
    let typeOfShift = "";
    if (shiftType === 1) {
      typeOfShift = "Day";
    } else if (shiftType === 2) {
      typeOfShift = "Eve";
    } else if (shiftType === 3) {
      typeOfShift = "Noc";
    }
    return typeOfShift;
  };

  const getRoleName = (roleIds: number[]) => {
    if (!!!roleIds || roleIds.length === 0) {
      return "";
    }
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  return (
    <>
      <CalendarShareShiftDetail
      id={props.openShiftDetail.id}
        shiftType={getOpenShiftType(props.openShiftDetail.shift_type)}
        range={`${format(props.openShiftDetail.start_date, "h a")} - ${format(
          props.openShiftDetail.end_date,
          "h a"
        )}`}
        qualification={getRoleName(props.openShiftDetail.role_ids)}
      />
    </>
  );
};
