import { useEffect } from "react";
import { currentActiveScreenState } from "../../libs/state-management-lib";
import { MarketPlaceTemplate } from "../template/marketPlaceTemplate";

export const MarketPlace = () => {
  useEffect(() => {
    currentActiveScreenState.setState("/marketplace");
  }, []);
  
  return (
    <>
      <MarketPlaceTemplate />
    </>
  );
};
