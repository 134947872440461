import {
  emptyAgencyDetail,
  emptyDepartmentDetail,
  EmptyOrganizationDetail,
  emptyOrgRole,
  emptyOrgOnboardingDetail,
  emptyRoleDetail,
  emptyShiftSeries,
  emptyShareShift,
  emptyShareOpenShift,
  emptyShiftRotation,
  emptyAgencyMappingDetail,
  emptyStaffDetail,
} from "../common-lib";
import { agencyDetailState } from "./agencyState";
import { searchShiftLocationState } from "./calendarState";
import {
  loadingIconState,
  successAlertState,
  updateAlertState,
  errorAlertState,
  errorMessageState,
  activeStatusAlertState,
  activeConfirmationPopupState,
  inActiveConfirmationPopupState,
  deleteConfirmationPopupState,
  numberOfStaffBedListState,
  numberOfEmployeeListState,
  locationTypeListState,
  weekDayStartListState,
} from "./commonState";
import { departmentDetailState } from "./departmentState";
import { accessTokenState, SignInState } from "./masterState";
import {
  organizationDetailState,
  orgRoleState,
  orgRoleListState,
  orgRolePermissionListState,
  moduleListState,
  subModuleListState,
  addressListState,
  organizationOnboardingState,
} from "./organizationState";
import { roleDetailState, roleListState } from "./roleState";
import {
  shiftListState,
  shiftSeriesState,
  shareShiftState,
  openShiftListState,
  shareOpenShiftState,
  shiftRotationState,
  customTagListState,
} from "./shiftState";
import {
  agencyDetailListState,
  agencyMappingState,
  agencyMappedDetailListState,
} from "./staffingPartnerState";
import { staffDetailState, staffDetailListState } from "./staffState";
import { unitListState, orgUnitDetailListState } from "./unitState";

export const resetAllState = () => {
  agencyDetailState.setState(emptyAgencyDetail);
  searchShiftLocationState.setState("");
  loadingIconState.setState(false);
  successAlertState.setState(false);
  updateAlertState.setState(false);
  errorAlertState.setState(false);
  errorMessageState.setState({
    errorMessage: "",
  });
  activeStatusAlertState.setState(false);
  activeConfirmationPopupState.setState(false);
  inActiveConfirmationPopupState.setState(false);
  deleteConfirmationPopupState.setState(false);
  numberOfStaffBedListState.setState({ numberOfStaffBedList: [] });
  numberOfEmployeeListState.setState({ numberOfEmployeeList: [] });
  locationTypeListState.setState({ locationTypeList: [] });
  departmentDetailState.setState(emptyDepartmentDetail);
  weekDayStartListState.setState({ weekDayStartList: [] });
  organizationDetailState.setState(EmptyOrganizationDetail);
  orgRoleState.setState(emptyOrgRole);
  orgRoleListState.setState({ orgRoleList: [] });
  orgRolePermissionListState.setState({ orgRolePermissionList: [] });
  moduleListState.setState({ moduleList: [] });
  subModuleListState.setState({ subModuleList: [] });
  addressListState.setState({ addressList: [] });
  organizationOnboardingState.setState(emptyOrgOnboardingDetail);
  roleDetailState.setState(emptyRoleDetail);
  roleListState.setState({ roleList: [] });
  shiftListState.setState({ shiftList: [] });
  shiftSeriesState.setState(emptyShiftSeries);
  shareShiftState.setState(emptyShareShift);
  openShiftListState.setState({ openShiftList: [] });
  shareOpenShiftState.setState(emptyShareOpenShift);
  shiftRotationState.setState(emptyShiftRotation);
  customTagListState.setState({ customTagList: [] });
  agencyDetailListState.setState({ agencyDetailList: [] });
  agencyMappingState.setState(emptyAgencyMappingDetail);
  agencyMappedDetailListState.setState({ agencyMappedDetailList: [] });
  staffDetailState.setState(emptyStaffDetail);
  staffDetailListState.setState({ staffDetailList: [] });
  unitListState.setState({ unitDetailList: [] });
  orgUnitDetailListState.setState({ orgUnitDetailList: [] });
  accessTokenState.setState({ token: "" });
  SignInState.setState({
    userName: "",
    password: "",
    mobileNumber: "",
    otp: "",
  });
};
