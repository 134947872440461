import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {LicenseInfo} from '@mui/x-license';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_LICENSE_KEY));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

