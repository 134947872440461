import * as React from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const NumberInput = React.forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      disabled={!!props.disabled && props.disabled ? true : false}
      slots={{
        root:
          props.disabled ? StyledInputRootDisabled : StyledInputRoot,
        input:
          props.disabled
            ? StyledInputElementDisabled
            : StyledInputElement,
        incrementButton:
          props.disabled ? StyledButtonDisabled : StyledButton,
        decrementButton:
          props.disabled ? StyledButtonDisabled : StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <KeyboardArrowUpIcon />,
        },
        decrementButton: {
          children: <KeyboardArrowDownIcon />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

interface INumberInputField {
  placeholder?: string;
  disabled?: boolean;
  value: number | null;
  handleChange: (event: any, value: number | null) => void;
}

export const NumberInputField = (props: INumberInputField) => {
  return (
    <NumberInput
      placeholder={props.placeholder}
      disabled={!!props.disabled && props.disabled ? true : false}
      min={1}
      aria-label="number input"
      value={props.value == null ? 0 : props.value}
      onChange={(event, val) => {
        props.handleChange(event, val);
      }}
    />
  );
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  width:100%;
  height:40px;
  font-family: inherit;
  font-weight: 400;
  border-radius: 8px;
  color: black;
  background: #F5FAFE;
  border: 1px solid #CFE5FC;
  box-shadow: #F5FAFE;
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px; 
`
);

const StyledInputRootDisabled = styled("div")(
  ({ theme }) => `
  width:100%;
  height:40px;
  font-family: inherit;
  font-weight: 400;
  border-radius: 8px;
  color: black;
  background: #F1F2F4;
  border: 1px solid #ABB2BA;
  box-shadow: #F5FAFE;
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px; 
`
);

const StyledInputElement = styled("input")(
  ({ theme }) => `
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: black;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);

const StyledInputElementDisabled = styled("input")(
  ({ theme }) => `
  font-size: 16px;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: #ABB2BA;
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  border: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
    &:hover {
    cursor: pointer;  
  }

  

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    background:none;
    color:#0D80F2;
    padding-right:15px;
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    background:none;
    color:#0D80F2;
    padding-right:15px;
`
);

const StyledButtonDisabled = styled("button")(
  ({ theme }) => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  appearance: none;
  padding: 0;
  width: 19px;
  height: 19px;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1;
  box-sizing: border-box;
  border: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
    &:hover {
    cursor: pointer;  
  }

  

  &.${numberInputClasses.incrementButton} {
    grid-column: 2/3;
    grid-row: 1/2;
    background:none;
    color:#ABB2BA;
    padding-right:15px;
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 2/3;
    grid-row: 2/3;
    background:none;
    color:#ABB2BA;
    padding-right:15px;
`
);
