import { Avatar, Box } from "@mui/material";
import { Typography14_600 } from "../../typography";
import { orgRoleListState } from "../../../../libs/state-management-lib";

interface ICalendarRotationTalentBox {
  staffName: string;
  roleIds: number[];
  showAvatar: boolean;
}

export const CalendarRotationTalentBox = (props: ICalendarRotationTalentBox) => {

  const getRoleName = (roleIds: number[]) => {
    let roleName = "";
    orgRoleListState.getState().orgRoleList.forEach((element) => {
      if (roleIds.find((cc) => cc === element.id)) {
        roleName = !!roleName ? roleName + ", " : "";
        roleName = roleName + element.abbreviation;
      }
    });

    return roleName;
  };

  return (
    <>
      <Box
        sx={{
          width: "calc(100% - 16px)",
          height:"26px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {/* <FormCheckbox /> */}
        {props.showAvatar && (<Avatar
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "8px",
          }}
          variant="square"
        />)}
        <Typography14_600 text={props.staffName} color="#17191C" />
        <Typography14_600 text={getRoleName(props.roleIds)} color="#737F8C" />
      </Box>
    </>
  );
};
