import {
  axiosInstance,
  handleApiErrorAlert,
} from "../common-lib";
import {
  accessTokenState,
} from "../state-management-lib";

const platform_base_url = `${process.env.REACT_APP_PLATFORM_URL}api/Auth`;

export const generateOTP = async (loginDetail: any) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/generateUserOTP`,
      loginDetail
    );

    if (!!response && !!response.data) {
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};

export const generateLoginToken = async (loginDetail: any) => {
  try {
    let response = await axiosInstance.post(
      `${platform_base_url}/generateToken`,
      loginDetail
    );

    if (!!response && !!response.data) {
      accessTokenState.setState({ token: response.data });
    }
  } catch (error: any) {
    handleApiErrorAlert(error);
    throw error;
  }
};
