import { Box, Typography } from "@mui/material";
import { CloseIcon, ContainedButton, OutlinedButton } from "../../atom";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";

interface ICalendarStaffFilterModal {
  handleCancelStaffFilterClick: () => void;
}

export const CalendarStaffFilterModal = (props: ICalendarStaffFilterModal) => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "26%",
          height: "auto",
          top: "150px",
          left: "455px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          border: "1px solid #E3E5E8",
          boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1)",
          background: "#FFFFFF",
          zIndex:"1100",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            width: "calc(100%-32px)",
            height: "calc(10%-32px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px",
            gap: "16px",
          }}
        >
          <Box
            sx={{
              width: "90%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#17191C",
              }}
            >
              Talent filter
            </Typography>
          </Box>
          <Box
            sx={{
              width: "10%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={props.handleCancelStaffFilterClick}
          >
            <CloseIcon sx={{color:"#0D80F2"}} />
          </Box>
        </Box>
        {/* Body */}
        <Box
          sx={{
            width: "100%",
            height: "80%",
            display: "flex",
            flexDirection: "column",
            padding: "16px 0 16px 0",
            gap: "8px",
          }}
        >
          <Box
            sx={{
              width: "calc(100%-32px)",
              display: "flex",
              flexDirection: "column",
              padding: "0 16px 0 16px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#737F8C",
                }}
              >
                Work Status
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox />
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      All
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox />
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Booked
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      No book
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Vacation
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox />
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Sick
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Available
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    height: "33%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      No Shifts
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "calc(100%-32px)",
              display: "flex",
              flexDirection: "column",
              padding: "0 16px 0 16px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "10%",
                display: "flex",
                flexDirection: "row",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#737F8C",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#737F8C",
                }}
              >
                Shift status
              </Typography>
              
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      All
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Open
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Assigned
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      In progress
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Completed
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Cancelled
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "calc(100%-32px)",
              display: "flex",
              flexDirection: "column",
              padding: "0 16px 0 16px",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#737F8C",
                }}
              >
                Shift occurances
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "90%",
                display: "flex",
                flexDirection: "row",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      All
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Open
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Filled
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Rotation
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Sequence
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Individual
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <FormCheckbox/>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#17191C",
                        lineHeight: "24px",
                      }}
                    >
                      Cancelled
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Footer Button */}
        <Box
          sx={{
            width: "calc(100%-32px)",
            height: "calc(10%-32px)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              width: "30%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <OutlinedButton
            disabled
              text={"Reset"}
              handleClick={props.handleCancelStaffFilterClick}
              sx={{height:"40px"}}
            />
          </Box>
          <Box
            sx={{
              width: "70%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "right",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <OutlinedButton
              text={"Cancel"}
              handleClick={props.handleCancelStaffFilterClick}
              sx={{height:"40px"}}
            />
            <ContainedButton
            disabled
              text={"Apply"}
              handleClick={function (): void {}}
              sx={{height:"40px"}}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
