import { Box, FormControlLabel, RadioGroup } from "@mui/material";
import {
  FormContainerBox,
  FormPlacementBox,
  LableTextFieldBox,
  PhoneNumberTextField,
  PlatformTextField,
  TextFieldPlacementBox,
  Typography12_600,
  Typography16_400,
  Typography18_600,
} from "../../atom";
import {  useLayoutEffect, useState } from "react";
import { GenericMasterData } from "../../../libs/model-lib";
import {
  staffTypeListState,
} from "../../../libs/state-management-lib";
import { getErrorDetail } from "../../../libs/common-lib";
import { FormCheckbox } from "../../atom/checkbox/formCheckbox";
import { useOrganizationDetailContext } from "../../../libs/context-lib/organizationDetailContext";

interface IOrgContactInfoForm {
  errorMessage: any[];
}

export const OrgContactInfoForm = (props: IOrgContactInfoForm) => {
  const [staffTypeList, setStaffTypeList] = useState<GenericMasterData[]>(
    staffTypeListState.getState().staffTypeList
  );
  const { organizationDetail, setOrganizationDetail } =
    useOrganizationDetailContext();

  useLayoutEffect(() => {
    const staffTypeListSub = staffTypeListState.subscribe(async (state) => {
      setStaffTypeList(state.staffTypeList);
    });

    return () => {
      staffTypeListSub();
    };
  }, []);

  const handleStaffTypeCheck = (event: any) => {
    let staffType = organizationDetail.staff_types;

    if (event.target.checked) {
      staffType.push(parseInt(event.target.value));
    } else {
      staffType = staffType.filter((cc) => cc != parseInt(event.target.value));
    }

    setOrganizationDetail({
      ...organizationDetail,
      staff_types: staffType,
    });
  };
  return (
    <>
      <FormPlacementBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600
              text="Main organization contact"
              color="#17191C"
            />
          </Box>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Name" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter name"}
                  value={organizationDetail.contact_name}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      contact_name: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "contact_name",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Title" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter title"}
                  value={organizationDetail.contact_title}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      contact_title: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "contact_title",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
          <TextFieldPlacementBox>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Phone number" color="#737F8C" />
                <PhoneNumberTextField
                  placeholder="Enter phone number"
                  value={organizationDetail.contact_phone_number}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      contact_phone_number: e,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "contact_phone_number",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
            <Box sx={{ width: "50%" }}>
              <LableTextFieldBox>
                <Typography12_600 text="Email" color="#737F8C" />
                <PlatformTextField
                  placeholder={"Enter email"}
                  value={organizationDetail.contact_email}
                  handleChange={(e: any) => {
                    setOrganizationDetail({
                      ...organizationDetail,
                      contact_email: e.target.value,
                    });
                  }}
                  errorMessage={getErrorDetail(
                    "contact_email",
                    props.errorMessage
                  )}
                />
              </LableTextFieldBox>
            </Box>
          </TextFieldPlacementBox>
        </FormContainerBox>
        <FormContainerBox>
          <Box sx={{ width: "100%" }}>
            <Typography18_600
              text="How is your organization staffed?"
              color="#17191C"
            />
          </Box>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "40px",
              }}
            >
              {staffTypeList.map((cc) => (
                <FormControlLabel
                  sx={{
                    alignContent: "center",
                    gap: "8px",
                  }}
                  key={cc.id}
                  control={
                    <FormCheckbox
                      checked={
                        organizationDetail.staff_types.filter(
                          (dd) => dd == cc.id
                        ).length > 0
                      }
                      value={cc.id}
                      onChange={handleStaffTypeCheck}
                    />
                  }
                  label={<Typography16_400 text={cc.name} color="#17191C" />}
                />
              ))}
            </Box>
          </RadioGroup>
        </FormContainerBox>
      </FormPlacementBox>
    </>
  );
};
