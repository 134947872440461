import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export const searchShiftLocationState = create<string>()(
  devtools(
    persist(() => "", {
      name: "searchShiftLocationState",
    })
  )
);
