import { Box } from "@mui/material";
import {
  ContainedDeleteButton,
  LogOutIcon,
  Typography18_600,
} from "../../atom";
import { useNavigate } from "react-router-dom";
import { resetAllState } from "../../../libs/state-management-lib/resetState";

export const PrivacySecuritySetting = () => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.clear();  
    resetAllState();
    navigate("/");
  };
  
  return (
    <>
      <Box
        sx={{
          width: "calc(100%-48px)",
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px 0px 24px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "42%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            padding: "16px",
            gap: "14px",
            border: "1px solid #F1F2F4",
          }}
        >
          <Typography18_600 text={"Log out"} color="#17191C" />
          <ContainedDeleteButton
            text={"Log out from all devices"}
            sx={{ width: "260px", height: "40px" }}
            startIcon={<LogOutIcon sx={{ width: "24px", height: "24px" }} />}
            handleClick={handleLogoutClick}
          />
        </Box>
      </Box>
    </>
  );
};
