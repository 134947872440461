import { Box } from "@mui/material";
import {
  LableTextFieldBox,
  Typography12_600,
  Typography14_400,
  Typography16_600,
} from "../../atom";
import { AutoCompleteTextInput } from "../../atom/textField/autoComplete";
import { useLayoutEffect, useState } from "react";
import {
  agencyDetailListState,
  organizationDetailState,
} from "../../../libs/state-management-lib";
import { getAgencyByName } from "../../../libs/services-lib";
import { emptyAgencyMappingDetail } from "../../../libs/common-lib";
import { useAgencyMappingDetailContext } from "../../../libs/context-lib/staffingPartnerContext";

interface IStaffingPartnerAgencyNameForm {
  disableName: boolean;
}

export const StaffingPartnerAgencyNameForm = (
  props: IStaffingPartnerAgencyNameForm
) => {
  const [agencyDetailList, setAgencyDetailList] = useState<any[]>([]);
  const { agencyMappingDetail, setAgencyMappingDetail } =
    useAgencyMappingDetailContext();

  useLayoutEffect(() => {
    const agencyNameListStateSub = agencyDetailListState.subscribe(
      async (state) => {
        setAgencyDetailList(state.agencyDetailList);
      }
    );

    return () => {
      agencyNameListStateSub();
    };
  }, []);

  const handleAgencyName = async (event: any, value: any) => {
    let orgId = organizationDetailState.getState().id;
    if (event.target.value.length >= 3) {
      await getAgencyByName(
        event.target.value,
        organizationDetailState.getState().id
      );
    }

    setAgencyMappingDetail({
      ...agencyMappingDetail,
      agency_name: event.target.value,
    });
  };

  const handleAutocompleteChange = (event: any, value: any) => {
    if (!!value) {
      setAgencyMappingDetail({
        ...agencyMappingDetail,
        agency_id: value.id,
        agency_name: value.name,
      });

      return;
    }

    setAgencyMappingDetail(emptyAgencyMappingDetail);
    setAgencyDetailList([]);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          "& p": {
            color: "#17191C !important",
          },
        }}
      >
        <Typography16_600 text={"Agency information"} />
        <Typography14_400
          text={
            "Connect to staffing partners within the ShiftNex platform. Also you can invite a new staffing partner to use the ShiftNex platform if they don’t have an account yet."
          }
        />
        <LableTextFieldBox>
          <Typography12_600 text="Agency name" color="#737F8C" required />
          <AutoCompleteTextInput
            onInputChange={(e: any, value: any) => {
              handleAgencyName(e, value);
            }}
            onChange={handleAutocompleteChange}
            options={agencyDetailList}
            getOptionLabel={(option: any) =>
              typeof option === "string" ? option : option.name}
            label={""}
            placeholder={"Enter agency name"}
            freeSolo={true}
            disabled={props.disableName}
          />
        </LableTextFieldBox>
      </Box>
    </>
  );
};
